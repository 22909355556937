import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import Logo from '../Assets/img/logo/wanderlust no drop shadow.png';
import banner from '../Assets/img/banner.png'
import img1 from "../Assets/img/how it works/1.PNG";
import img2 from "../Assets/img/how it works/2.PNG";
import img3 from "../Assets/img/how it works/3.PNG";
import tableimg from "../Assets/img/how it works/pricing table.png";
import { Link } from 'react-router-dom';


function Howitworks() {
  return (
    <>
      <div className="overflow-hidden py-24 sm:py-32">
        <div className="mx-auto max-w-8xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-6 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4 mx-auto">
              <div className="lg:max-w-lg ">
              <div className=''>
                  <div className='flex justify-center'>
                  <img  src={Logo} width="250" alt="" />
                  </div>
                  </div>
                <div className='flex flex-row'>
                  <h1 className='text-6xl text-center font-bold'>How it works</h1>
                </div>
                <dl className="mt-3 max-w-xl space-y-8 text-base leading-7 text-gray-400 lg:max-w-none">
                  <p className='text-gray-600 w-12/12'>Many 3rd-party sites quote discounts, but the Wanderlust Card is the only membership that offers a true 10% discount off the original booking price. You’ll also get access to other unique discounts offered at each hostel:</p>
                  <p className='py-2'>
                    <ul className='p-1 text-black font-medium'>
                      <li>✓ 10% off + stays</li>
                      <li>✓ 10% off + tours</li>
                      <li>✓ Exclusive hostel offers : free drinks, discounted meals, activities</li>
                      <li>✓ Exclusive community connection</li>
                    </ul>
                  </p>
                </dl>
              </div>
            </div>
            <div className='flex justify-center respon'>
              <img
                src={banner}
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl sm:w-[57rem] md:-ml-4 lg:-ml-0 p-42 "
              />
            </div>
          </div>
        </div>
        <div className='flex flex-row container mx-auto p-5 place-content-center space-y-1 '>
          <div className='flex flex-row respon'>
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            <img src={img3} alt="" /> 
          </div>
        </div>
      {/* <div className='container flex mx-auto justify-center py-12'> 
        <div className='flex flex-row p-2'>
          <div>
            <p className='text-2xl '>Simple No-Tricks Pricing: All Access for <span className='font-bold'>$10 a month</span></p>
            <p className='font-light'>Membership pays for itself in one stay! Includes all exclusive discounts at our partner hostels</p>
          </div>
          <div className='px-5'>
            <button type="submit" className="group relative flex w-full justify-center border-4  py-2 px-4 text-sm font-medium uppercase font-semibold border-grey-400 bg-blue-300  text-white shadow-md "> <Link to='/signup'>Signup</Link></button>
          </div>
        </div>
        
      </div> */}
      <div className='container flex  mx-auto py-3'>
        <img src={tableimg} alt="" />
        {/* <img src={tableimg} alt="" /> */}

      </div>
      </div>
      
      
    </>
  )
}


export default Howitworks;

