import { Route, Routes, Navigate } from "react-router-dom";
import Forget from "./Pages/Forget";
import Reset from "./Pages/Reset";
import Homepage from "./Pages/Homepage";
import SignIn from "./Pages/SignIn";
import Howitworks from "./Pages/Howitworks";
import About from "./Pages/About";
import Signup from './Pages/Signup';
import PasswordPage from "./Pages/PasswordPage";
import Payment from "./Pages/payment";
import Navbar from "./component/Navbar";
import Footer from "./component/Footer";
import Contactus from "./Pages/Contactus";
import PrivateRoute from './component/PrivateRoute';
import Settings from "./Pages/Settings.js";
import Hosteldetail from "./Pages/Hosteldetail";
import Accountpage from "./Pages/Accountpage";
import Errorpage from "./Pages/Errorpage";
import Account from "./Pages/Account pages/Account";
import Planbilling from "./Pages/Account pages/Planbilling";
import Mapdetail from "./Pages/Mapdetail";
import Mapview from "./Pages/Mapview";
import PaymentDetails from "./Pages/PaymentDetails";
import CheckoutPage from "./Pages/CheckoutPage";
import Success from "./Pages/Success";
import Failure from "./Pages/Failure";
import CustomerSubscription from "./Pages/CustomerSubscription";
// Hostel
import HostelSignIn from "./Pages/hostel/HostelSignIn";
import HostelSignUp from "./Pages/hostel/HostelSignUp";
import HostelApproval from "./Pages/hostel/HostelApproval";
import HostelDashboard from "./Pages/hostel/HostelDashboard";

import HostelAccount from "./component/hostelDashboard/HostelAccount";
import HostelHelp from "./component/hostelDashboard/HostelHelp";
import HostelProfile from "./component/hostelDashboard/HostelProfile";
import HostelPassword from "./component/hostelDashboard/HostelPassword";
import PartnerDetails from "./component/hostelOnboard/PartnerDetails";
import SignUpSuccess from "./component/hostelOnboard/SignUpSuccess";
import SetPassword from "./component/hostelOnboard/SetPassword";
import PageDetails from "./component/hostelOnboard/PageDetails";
import HostelForgetPassword from "./Pages/hostel/HostelForgetPassword";
import HostelResetPassword from "./Pages/hostel/HostelResetPassword";
import WanderlustOwner from "./Pages/WanderlustOwner";


function App() {
  return (
    <div className="App">
      {/* Routes */}
      <Navbar />

      <Routes>
        <Route path="/" element={<Homepage />} />


      </Routes>

      <Routes>
        <Route path='/signin' exact element={<SignIn />} />
        <Route path='/signup' exact element={<Signup />} />
        <Route path='/howitworks' exact element={<Howitworks />} />
        <Route path='/forget' exact element={<Forget />} />
        <Route path='/About' exact element={<About />} />
        <Route path='/resetpassword/:userId/:token' exact element={<Reset />} />
        <Route path='/user/activate/:token' exact element={<PasswordPage />} />
        <Route path='/contactus' exact element={<Contactus />} />
        <Route path='/hosteldetail/:id' exact element={<Hosteldetail />} />
        {/* <Route path='/hosteldetail' exact element={<Hosteldetail />} /> */}
        <Route path='/errorpage' exact element={<Errorpage />} />
        <Route path="/accountinfo" element={<Accountpage />} />
        <Route path="/mapdetail" element={<Mapdetail />} />
        <Route path="/mapview" element={<Mapview />} />
        <Route path="/failed" element={<Failure />} />
        <Route path="/customer/subscription/:id" element={<CustomerSubscription />} />
        <Route element={<PrivateRoute />}>
          <Route path='/user/settings' element={<Settings />} />
          <Route path='/payment' exact element={<PaymentDetails />} />
          <Route path='/checkout' exact element={<CheckoutPage />} />
          <Route path="/success" element={<Success />} />
          <Route path="/accountinfo" element={<Accountpage />} >
            <Route index element={<Account />} />
            <Route path="planbilling" element={<Planbilling />} />
          </Route>
        </Route>

        <Route path="/wanderlust/owner/approve/hostel/admin/:key" exact element={<WanderlustOwner />} />
        {/* Hostel Routing*/}
        <Route path="/hostel/login" element={<HostelSignIn />} />
        <Route path="/hostel/signup" element={<HostelSignUp />} />
        <Route path="/hostel/approved" element={<HostelApproval />} />
        <Route path="/hostel/pagedetail" element={<PageDetails />} />
        <Route path='/hostel/forgetpassword' exact element={<HostelForgetPassword />} />
        <Route path='/hostel/resetpassword/:ownerId/:token' exact element={<HostelResetPassword />} />

        <Route path="/hostel/dashboard" element={<HostelDashboard />}>
          <Route index element={<HostelProfile />} />
          <Route path="account" element={<HostelAccount />} />
          <Route path="password" element={<HostelPassword />} />
          <Route path="help" element={<HostelHelp />} />
        </Route>

      </Routes>


      <div className=" w-full relative">
        <Footer />
      </div>
    </div >
  );
}

export default App;
