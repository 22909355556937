import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API } from '../../../Backend';
import { useDispatch } from 'react-redux';
import { adminupdate } from '../../../redux/actions/HostelAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Hosteldetail() {
  const [details, setDetails] = useState({ startPrice: '', description: '', bookingOption: '' });
  const { hostelDetails } = useSelector((state) => state.adminReducer.hostelDetails)
  const dispatch = useDispatch()

  useEffect(() => {
    if (hostelDetails !== undefined) {
      console.log(hostelDetails)
      setDetails({ startPrice: hostelDetails.startPrice, description: hostelDetails.description, bookingOption: hostelDetails.bookingOption })
    }
  }, [hostelDetails])

  const handleChange = (e) => {
    if (e.target.name === 'website' || e.target.name === 'whatsapp') {
      setDetails({
        ...details,
        bookingOption: e.target.name
      })
    } else {
      setDetails({
        ...details,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleSubmit = () => {
    if (details.startPrice !== '' && details.description !== '' && details.bookingOption !== '') {
      dispatch(adminupdate(details)).then((res) => {
        if (res.success) {
          toast.success("Hostel Details Updated successfully")
        } else {
          toast.error(res.message)
        }
      })
    }

  }


  return (
    <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto  '>
      <div className='flex flex-row justify-between '>
        <div>
          <h1 className='font-bold '>Hostel Details</h1>
        </div>
        <div className=''>
          <button
            type="submit"
            onClick={handleSubmit}
            className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
          >
            Save
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 respon">
        <div className='p-1'>
          <div className="mt-2  respon ">
            <label htmlFor="">Starting at price</label>
            <div className="flex rounded-md shadow-sm  ring-gray-300 ring-1 sm:max-w-md">
              <span className="flex select-none items-center p-1 text-gray-500 sm:text-sm"> $ </span>
              <input
                type="text"
                name="startPrice"
                value={details.startPrice}
                onChange={handleChange}
                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6" placeholder='9'
              />

            </div>
          </div>
          <h2 className='py-4 font-bold text-sm respon'>Booking Direct Link goes to:</h2>
          <div className="py-5 ">
            <div className='flex flex-row respon'>
              <div className='px-2'>
                <p className='text-center py-3'>
                  <input type="radio" checked={details.bookingOption === 'website' ? true : false} name="website" id="" onChange={handleChange} />
                  Website
                </p>
              </div>

              {/* <div className="flex rounded-md shadow-sm  sm:max-w-md ring-1 ring-gray-300">
                <span className="flex select-none items-center p-1 text-gray-500 sm:text-sm "> http:// </span>
                <input
                  type="text"
                  name="Amount"
                  className="block flex-1 border-0 bg-transparent py-1  text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6" placeholder=''
                />
              </div> */}
            </div>

          </div>
          <div className="py-5 respon">
            <div className='flex flex-row respon'>
              <div className='px-2'>
                <p className='text-center py-3'><input type="radio" name="whatsapp" checked={details.bookingOption === 'whatsapp' ? true : false} onChange={handleChange} id="" /> Whatsapp </p></div>

              {/* <div className="flex rounded-md shadow-sm  sm:max-w-md ring-1 ring-gray-300">
                <span className="flex select-none items-center p-1 text-gray-500 sm:text-sm "> EC </span>
                <input
                  type="text"
                  name="Amount"
                  className="block flex-1 border-0 bg-transparent py-1  text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6" placeholder=''
                />
              </div> */}
            </div>
          </div>

        </div>
        <div className='respon'>
          <h1>Description</h1>
          <div className="mt-2">
            <textarea
              id="Message"
              name="description"
              rows={3}
              onChange={handleChange}
              value={details.description}
              className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:py-1.5 text-sm font-light sm:leading-6"
              placeholder="Awarded Best Hostel in Ecuador five years in a row! We offer you the best environment to meet people, enjoy amazing food, join fun activities (& party...), and get a good night's rest, all in a safe and secure environment! Enjoy Views of Quito from our brand new rooftop bar and restaurant with some of the best food and drinks in Quito, at a special price to our guests! We are close to all the main attractions Quito has to offer, and offer tours all over the country! "

            />
          </div>
        </div>

        <ToastContainer />

      </div>

    </div>
  )
}
