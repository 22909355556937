import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { API } from '../Backend';

const CustomerSubscription = () => {
    const { id } = useParams();
    const [status, setStatus] = useState("")
    const [email, setEmail] = useState("")
    useEffect(() => {

        const geturl = async () => {
            const authAxios = axios.create({
                baseURL: API,
                headers: {
                    "Content-type": 'application/json',
                }
            });

            const { data } = await authAxios.get(
                `/api/customer/qr/${id}`);
            console.log(data)
            if (data.success) {
                setStatus(data.member.subscriptionStatus)
                setEmail(data.member.userId.email)
            }
        }

        geturl()
    }, [])
    return (
        <div>
            {status !== 'InActive' ? `Subscription is Active for ${email}` : `Subscription is<b> InActive</b> for ${email}`}
        </div>
    )
}

export default CustomerSubscription