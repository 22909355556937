import React, {useState} from 'react'
import PartnerDetails from '../../component/hostelOnboard/PartnerDetails';
import SignUpSuccess from '../../component/hostelOnboard/SignUpSuccess';
import { Link ,Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HostelSignUp = () => {
    const { isAuth, error } = useSelector((state) => state.adminReducer);
    const [step, setStep] = useState(1);

    const addStep = () => {
        setStep(step+1);
    }

    if (isAuth) {
        return <Navigate to="/hostel/dashboard" />
    }

  return (
    <div className=''>
        <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    <div className="hidden bg-cover hotel-bg lg:block lg:w-1/3" >
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50 ">
                            <div>
                                <h2 className="text-2xl font-medium text-white text-center p-5">By Hostel Owners For Hostel Owners</h2>
                                
                                <ul className="text-white font-medium p-5">
                                    <li>More direct bookings</li>
                                    <li>Higher profit margin</li>
                                    <li>List your hostel or tour without 3rd party fees</li>
                                </ul>
                                <h2 className="text-2xl font-medium text-white text-center p-5">What makes you a great fit</h2>
                                
                                <ul className="text-white font-medium p-5">
                                    <li>High customer ratings</li>
                                    <li>Ability to book tours at hotel</li>
                                    <li>Ability to offer 10% on direct booking and tours</li>
                                </ul>
                                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-300 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"><Link to="/hostel/login">Sign In</Link></button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex items-center px-1 mx-auto  m-1">
                        {step === 1 && <PartnerDetails addStep={addStep}/>}
                        {step === 2 && <SignUpSuccess/>}
                    </div>
                </div>
            </div>
    </div>
  );
}

export default HostelSignUp