import React, { useState, useEffect, useReducer } from 'react';
import visaimg from "../../Assets/img/planbilling/visa.PNG";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API } from '../../Backend';
import moment from 'moment'
import { cancelMembership, getUser } from '../../redux/actions/UserAction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Planbilling() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false);
  const navigate = useNavigate()
  const [modal1, setModal1] = useState(false);
  const { user, isAuth } = useSelector((state) => state.userReducer);
  const [date, setDate] = useState('')
  const [subscribe, setSubscribe] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(null)


  useEffect(() => {
    if (isAuth === false) {
      navigate('/')
    }
  }, [isAuth])

  useEffect(() => {
    dispatch(getUser()).then((res) => { 
      console.log('plan billing', res)
      if (res.success) {
        setSubscribe(res.subscriptions.data)
        if (res.details !== null) {
          setPaymentMethod(res.details)
        } else {
          setPaymentMethod(null)
        }
      }
    })
  }, [])

  const toggleModal = () => {
    setModal(!modal);
  };
  const toggleModal1 = () => {
    setModal1(!modal1);
  };

  const handleMembership = async () => {
    dispatch(cancelMembership(user.subscriptionId)).then((res) => {
      console.log('rs', res)
      if (!res.success) {
        const dat1 = new Date(res.message * 1000)
        const date = moment(dat1).format('DD-MM-YY')
        toast.success(`Subscription will automatically cancelled on ${date}`)
      } else {
        if (res.subscription.status === 'trialing') {
          toast.success('Subscription will automatically cancelled after trial period end')
        } else if (res.subscription.status === 'active') {
          toast.success('Subscription will automatically cancelled after this month period end')
        }
      }
      toggleModal1()
    })
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  const handleCheckout = () => {
    navigate('/checkout')
  }


  return (
    <div className='container mx-auto py-4 '>
      <div className=' border-4 p-4   '>
        <div className='flex flex-row justify-between'>
          <div className='p-5'>
            {/* <p className=' text-black font-bold text-center justify-center text-2xl'>Current Subscription:
            <span className={`font-bold ${user.subscriptionStatus === 'InActive' ? "text-red-400" : "text-green-400"}`}>{user.subscriptionStatus === 'trialing' || user.subscriptionStatus === 'active' ? 'Active' : 'InActive'}</span></p> */}
            <p className='font-bold'>Status : <span className='font-bold text-green-400'>{user.subscriptionStatus}</span></p>
          </div>
          <div>
            {user.endDate === null ?
              <button onClick={handleCheckout}>Subscribe</button> :
              <div className='flex flex-col p-3 text-center bg-green-200 p-2'><p>Renew on :

                <span><p className='font-bold'>

                  {moment(user.endDate).format('DD-MM-YY')}
                </p></span>
              </p></div>}
          </div>
        </div>

      </div>
      <div className="grid grid-col-2 border-4 p-2">
        <div className='flex flex-row '>
          <div className='px-2 flex'>
            <div className='flex '>
              <div className="mt-5 md:col-span-2 md:mt-0 ">
                <h1 className='font-bold px-4 text-2xl'>Payment Method :</h1>
                <div className='flex flex-row p-2'>
                  <div className='grid py-10'>
                    {paymentMethod !== null &&
                      <img src={visaimg} width="150" alt="" />
                    }
                  </div>
                  <div className='flex flex-col px-6 justify-center'>
                    <p>{paymentMethod !== null && `Ending with ${paymentMethod.digit}`}</p>
                    <p> {user.subscriptionStatus === 'active' || user.subscriptionStatus === 'trialing' ?
                      `Renew on ${moment(user.endDate).format('DD-MM-YY')}`
                      : <button onClick={handleCheckout}>Subscribe</button>}</p>
                  </div>
                </div>
              </div>

            </div>
            <div className='p-3' >
              {

                paymentMethod !== null &&
                <button className='bg-black text-white p-1 px-4 '>Edit</button>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="flex border-4 flex-col justify-center" >
        <div><p className='font-bold text-2xl p-3'>Billing History</p></div>
        <div className='justify-center p-3 flex'><table className=" border border-slate-500 border-separate ">
          <thead className='border-2 text-center '>
            <tr className=''>
              <th className='border border-slate-600 p-3 text-center'>Date</th>
              <th className='border border-slate-600 p-3 text-center'>plan</th>
              <th className='border border-slate-600 p-3 text-center'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              subscribe.map((sub, index) => (
                <tr className='p-3' key={index}>
                  <td className='border border-slate-600 p-3 text-center'>{moment(new Date(sub.current_period_start * 1000)).format('DD-MM-YY')}</td>
                  <td className='border border-slate-600 p-3 text-center'>{sub.status === 'trialing' ? 'Free Trial' : 'Monthly'}</td>
                  <td className='border border-slate-600 p-3 text-center'>{sub.status === 'trialing' ? 'To be deducted' : '$10'}</td>
                  {/* <td className='border border-slate-600 p-3 text-center text-blue-400'>View receipt</td> */}
                </tr>
              ))
            }

          </tbody>
        </table></div>
      </div>

      <div className='p-3 border flex justify-center w-full'>

        <div className="flex flex-col ">

          {/* modal */}

          {modal1 && (
            <div className="modal ">
              <div className="overlay"></div>
              <div className="modal-content bg-white">
                <h2 className='font-bold p-3'>Cancel subscription</h2>
                <p className='text-sm font-light'>
                  Once you cancel, your subscription will not renew the next month. Your Wanderlust card will still be valid through the paid month
                </p>
                <div className='p-2 flex flex-row justify-end '>
                  <div className='px-2'>
                    <button className="px-2 bg-gray-400 px-5 py-2 text-sm text-white" onClick={toggleModal1}>
                      Back
                    </button>
                  </div>
                  <button type="button" className=" bg-red-400 px-2 py-2 text-sm text-white " onClick={handleMembership}>Cancel membership</button>
                </div>
              </div>
            </div>
          )}
          {user.subscriptionStatus !== 'InActive' &&
            <>
              <h1 className='font-bold text-lg p-1'>Cancel Subcription</h1>

              <div><p className='w-full text-sm'>Once you cancel, your subscription will not renew the next month. Your Wanderlust card will still be valid through the paid month</p></div>
            </>
          }
        </div>
        {user.subscriptionStatus !== 'InActive' &&
          <div className='py-4 px-3'><button type="button" className=" bg-red-400 px-2 py-2 text-sm text-white " onClick={toggleModal1}>Cancel Membership</button></div>
        }
      </div>
      <ToastContainer />
    </div >
  )
}
