import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { activateAccount, resetPassword } from '../redux/actions/UserAction'
import { useNavigate, useParams } from 'react-router-dom'


const PasswordPage = () => {
    const { token } = useParams()
    const [details, setDetails] = useState({ password: '', confirmPassword: '' })
    const { message, error, loading } = useSelector((state) => state.userReducer);
    const [loginerror, setLoginError] = useState();
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    // error states
    const [displayError, setDisplayError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    // success state
    const [successfull, setSuccessfull] = useState("")

    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        const { password, confirmPassword } = details
        if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
            displayErrorFunction("Please fill all the fields")
        } else if (password !== confirmPassword) {
            displayErrorFunction("Password not matched with Confirm Password")
        } else {
            dispatch(activateAccount(details, token))
                .then(res => {
                    console.log(res)
                    if (!res.success) {
                        displayErrorFunction(res.message)
                    } else {
                        localStorage.setItem('token', res.token)
                        setSuccess(true)
                        setSuccessfull(res.message)
                        setDetails({ password: '', confirmPassword: '' })
                        navigate('/checkout')
                    }
                })
        }
    }

    const displayErrorFunction = (message) => {
        setErrorMessage(message)
        setDisplayError(true)
        setTimeout(() => {
            setDisplayError(false)
            setErrorMessage("")
        }, 3000);
    }

    return (
        <div className='py-28'>
            <div className="flex items-center h-96">
                <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-sm m-auto">
                    <div className="mb-4">
                        <label
                            className="block font-bold mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="border border-gray-400 p-2 w-full"
                            id="password"
                            type="password"
                            name="password"
                            value={details.password}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            className="block font-bold mb-2"
                            htmlFor="confirmpassword"
                        >
                            Confirm Password
                        </label>
                        <input
                            className="border border-gray-400 p-2 w-full"
                            id="confirmpassword"
                            type="password"
                            name="confirmPassword"
                            value={details.confirmPassword}
                            onChange={handleChange}
                        />
                    </div>

                    {!displayError ? <></> : <span className='text-red-600'>{errorMessage}</span>}
                    {success ? <span className='text-green-600'> {message}</span> : ''}
                    <div className="flex space-x-4 justify-center">
                        <button className="px-6 h-12 uppercase font-semibold  
                        border-4 border-grey bg-blue-400  text-white " type="submit" onClick={handleSubmit}>
                            {loading ? 'loading...' : 'Reset Password'}
                        </button>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default PasswordPage;