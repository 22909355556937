import React, { useState, useEffect, useRef } from 'react'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import Hostelpayment from './Hostelpayment';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { adminupdate, hostelimage, adminLogout, deleteimage } from '../../redux/actions/HostelAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Geocode from "react-geocode";
import { LoadScript, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';

const HostelAccount = () => {
  const { hostelDetails } = useSelector((state) => state.adminReducer.hostelDetails);
  const dispatch = useDispatch()
  const [details, setDetails] = useState({
    hostelName: '',
    firstName: '',
    lastName: '',
    email: '',
    hostelPhone: '',
    ownerPhone: '',
    hostelAddress: '',
    website: ''
  })

  const [image, setImage] = useState(null)
  const [showImage, setShowImage] = useState(null)
  const [formErrors, setFormErrors] = useState({});
  const [dataError, setDataError] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showMultipleFiles, setMultipleFiles] = useState([])
  const [lat, setLat] = useState('')
  const [long, setLong] = useState('')
  const inputRef = useRef();
  useEffect(() => {
    if (hostelDetails !== undefined) {
      const { hostelName, firstName, lastName, hostelPhone, ownerPhone, hostelAddress, website } = hostelDetails
      setDetails({
        hostelName,
        firstName,
        lastName,
        email: hostelDetails.ownerId.email,
        hostelPhone,
        ownerPhone,
        hostelAddress,
        website
      })

      if (hostelDetails.image !== undefined) {
        setMultipleFiles([...hostelDetails.image])
        setImage(hostelDetails.image[0])
        setShowImage(hostelDetails.image[0])
      }
    }
  }, [hostelDetails])

  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlaces()[0];
    console.log(place);
    if (place) {
      const addresses = place.formatted_address;
      const { lat, lng } = place.geometry.location;
      console.log(lat(), lng());
      setLat(lat())
      setLong(lng())
      setDetails({
        ...details,
        hostelAddress: addresses
      })
    }
  }

  const handleChange = (e) => {
    if (formErrors) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: ""
      })
    }
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  const handleFileSelect = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const handleImage = (e) => {
    e.preventDefault()
    if (selectedFiles.length === 0) {
      toast.error('Please Select the Files')
      return
    }
    const formData = new FormData();
    for (const file of selectedFiles) {
      formData.append('image', file);
    }


    dispatch(hostelimage(formData)).then((res) => {
      if (res.success) {
        setImage(null)
        setSelectedFiles([])
        toast.success('Hostel Image Updated successfully')
      } else {
        setImage(null)
        setSelectedFiles([])
        toast.error(res.message)
      }
    })
  }

  const handleSubmit = () => {
    let formErrors = validateForm(details);

    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors);
    } else {
      try {
        const details1 = { ...details, long: long, lat: lat }
        dispatch(adminupdate(details1)).then((res) => {
          if (res.success) {
            toast.success("Account Details Updated")
          } else {
            toast.error(res.message)
          }
          setFormErrors({})
        })

      } catch (err) {
        setDataError(err.response.data.message)
        toast.error(err.response.data.message)
      }
    }

  }

  const validateForm = (details) => {
    let errors = {};
    let { firstName, lastName, ownerPhone, hostelPhone } = details;

    if (!firstName) {
      errors.firstName = "Please enter your First Name"
    }

    if (!lastName) {
      errors.lastName = "Please enter your Last Name"
    }
    if (!ownerPhone) {
      errors.ownerPhone = "Please enter your Phone Number";
    } else if (ownerPhone.length !== 10) {
      errors.ownerPhone = "Please enter a valid phone number"
    }

    if (!hostelPhone) {
      errors.hostelPhone = "Please enter your Phone Number";
    } else if (hostelPhone.length !== 10) {
      errors.hostelPhone = "Please enter a valid phone number"
    }
    let { hostelName, hostelAddress, website } = details;

    if (!hostelName) {
      errors.hostelName = "Please enter your Hostel Name";
    }
    if (!hostelAddress) {
      errors.hostelAddress = "Please enter your Hostel Address";
    }
    // if (!city) {
    //   errors.city = "Please enter Hostel's City";
    // }
    // if (!country) {
    //   errors.country = "Please enter Hostel's Country";
    // }
    // if (!zipCode) {
    //   errors.zipCode = "Please enter Hostel's Zip Code";
    // }
    if (!website) {
      errors.website = "Please enter Hostel's website";
    }
    return errors;

  }

  const handleLogout = () => {
    dispatch(adminLogout())
  }

  const deleteImage = (e, image) => {
    dispatch(deleteimage({ link: image })).then((res) => {
      if (res.success) {
        toast.success('Image Deleted Successfully')
      }
    })
  }
  let inputDatas;
  if (window.google && window.google.maps) {
    // Google Maps API is already loaded
    // No need to load it again
    inputDatas = (
      // <LoadScript googleMapsApiKey="AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c" libraries={["places"]}>
      // {() => (
      <StandaloneSearchBox
        onLoad={ref => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChanged}
      >

        <input
          type="text"
          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
          name="hostelAddress"
          placeholder=''
          defaultValue={details.hostelAddress}
        />
      </StandaloneSearchBox>
      // )}
      // </LoadScript>
    )
  } else {
    // Google Maps API is not loaded
    // You can proceed to load it using the LoadScript component or other methods
    inputDatas = (
      <LoadScript googleMapsApiKey="AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c" libraries={["places"]}>
        {() => (
          <StandaloneSearchBox
            onLoad={ref => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >

            <input
              type="text"
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

              placeholder=""
            />
          </StandaloneSearchBox>
        )}
      </LoadScript>
    )
  }

  return (
    <>
      <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto flex flex-col'>

        <div className=''>
          <div className='flex flex-row justify-between'>
            <h1 className='font-bold w-2/3'>Account Infomation</h1>
            <div className='flex '>
              <button
                onClick={handleSubmit}
                type="submit"
                className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
              >
                Save
              </button>
            </div>
          </div>
          <div className='grid grid-cols-3 respon'>
            <div>
              <div className="flex ">
                <div className="mt-2 flex items-center gap-x-3 flex-col">
                  {/* <UserCircleIcon className="h-32 w-32 text-gray-300" aria-hidden="true" /> */}
                  {
                    showMultipleFiles.map((file, index) => (
                      <>
                        <img src={file} width="100px" height="150px" style={{ borderRadius: '50%', marginBottom: '5px' }} />
                        <button onClick={(e) => deleteImage(e, file)}>delete</button>
                      </>
                    ))
                  }
                  <button
                    type="button"
                    className=" px-2.5 py-1.5 text-sm text-gray-900 "
                  >
                    Upload Photo
                  </button>
                  <input type="file" multiple onChange={handleFileSelect} name="image" />
                  <button onClick={handleImage}>save</button>
                </div>
              </div>
            </div>
            <div className='mt-1 grid grid-cols-1 w-2/3 respon'>
              <div className="">
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                  Hostel Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="hostelName"
                    disabled
                    value={details.hostelName}
                    onChange={handleChange}
                    id=""
                    autoComplete=""
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formErrors && formErrors.hostelName && <p className="my-2 text-sm text-red-600">{formErrors.hostelName}</p>}

                </div>
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                  Hostel Address
                </label>
                <div className="mt-2">
                  {inputDatas}
                  {formErrors && formErrors.hostelAddress && <p className="my-2 text-sm text-red-600">{formErrors.hostelAddress}</p>}

                </div>

                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                  Hostel Website
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="website"
                    disabled
                    value={details.website}
                    onChange={handleChange}
                    id=""
                    autoComplete=""
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formErrors && formErrors.website && <p className="my-2 text-sm text-red-600">{formErrors.website}</p>}

                </div>
                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-900">
                  Hostel Phone Number(Whatsapp)
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="hostelPhone"
                    disabled
                    value={details.hostelPhone}
                    onChange={handleChange}
                    id=""
                    autoComplete=""
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {formErrors && formErrors.hostelPhone && <p className="my-2 text-sm text-red-600">{formErrors.hostelPhone}</p>}

                </div>

              </div>

            </div>


            {/* account info */}

            <div className="border-b border-gray-900/10 pb-1">
              <div className="">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    First name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="firstName"
                      value={details.firstName}
                      onChange={handleChange}
                      id="first-name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formErrors && formErrors.firstName && <p className="my-2 text-sm text-red-600">{formErrors.firstName}</p>}

                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    Last name
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="lastName"
                      value={details.lastName}
                      onChange={handleChange}
                      id="last-name"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formErrors && formErrors.lastName && <p className="my-2 text-sm text-red-600">{formErrors.lastName}</p>}

                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={details.email}
                      disabled
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                    Owner Contact Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="ownerPhone"
                      value={details.ownerPhone}
                      onChange={handleChange}
                      id="street-address"
                      autoComplete="street-address"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formErrors && formErrors.ownerPhone && <p className="my-2 text-sm text-red-600">{formErrors.ownerPhone}</p>}

                  </div>
                  <div>
                    <button onClick={handleLogout} type="submit" className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-blue-300 shadow-lg focus:outline-none">Logout</button>

                  </div>
                </div>
                <ToastContainer />
                {/* <div className='py-4'>
                  <p className='p-2'>Hostel takes reservations through:</p>
                  <p className='text-sm px-3 p-2'>Website / Online booking</p>
                  <p className='text-sm px-3 p-2'>Whatsapp</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </div >
      <Hostelpayment />
    </>
  );
}

export default HostelAccount