import React from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"

const PageDetails = ({ details, handleChange, handleSubmit }) => {
    return (
        <div className="flex-1">
            <div className="text-center">
                <div className='flex justify-center'>
                    <img src={Logo} width='100' alt="" />
                </div>
                <p className="mt-3 text-black text-4xl">Let's Set Up Your Hostel Page</p>
            </div>
            <div className="mt-8">
                <p className=' font-medium py-2 '>Hostel Description</p>
                <p className='py-1 text-sm leading-6 text-gray-600'>This is the public description of your hostel to be shown to prospective guests</p>
                <textarea value={details.description} name="description" onChange={handleChange} className="block w-full px-4 py-2 mt-2 p-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />

            </div>
            <div className="mt-8">
                <p className=' font-medium py-2 '>Starting at price</p>
                <p className='py-1 text-sm leading-6 text-gray-600'>This is the “Starting at” price advertised on our site. This should be your lowest direct booking price.</p>
                <input type="text" value={details.startPrice} name="startPrice" onChange={handleChange} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />

            </div>
            {/* <fieldset className='py-'>
          <legend className="text-sm font-semibold leading-6 text-gray-900 py-6">Booking Direct Link Goes to:</legend>
          <p className="mt-1 text-sm leading-6 text-gray-600">When a customer clicks “Book Now”, we will send them to either your booking site or your Whatsapp group to book.</p>
          <div className="mt-6 space-y-6">
              <div className="flex items-center gap-x-3">
              <input
                  id="push-everything"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                  Website
              </label>
              <input type="text"  className="block w-full px-4 py-2  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
              </div>
              <div className="flex items-center gap-x-3">
              <input
                  id="push-email"
                  name="push-notifications"
                  type="radio"
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                  Whatsupp
              </label>
              <input type="number"  className="block w-full px-4 py-2  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
              </div>
              
          </div>
          </fieldset> */}
            <div className='py-4'>
                <button onClick={handleSubmit} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">Save and sign-In</button>
            </div>
        </div>
    )
}

export default PageDetails