import React, { useState } from 'react'
import axios from 'axios'
import { API } from '../../Backend.js'

const HostelHelp = () => {
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [successMsg, setSucessMsg] = useState('')

  const handleSubmit = async () => {
    const token = JSON.parse(localStorage.getItem('hostelInfo')).token;

    console.log(token)

    const authAxios = axios.create({
      baseURL: API,
      headers: {
        "Content-type": 'application/json',
        Authorization: `Bearer ${token}`
      }
    });


    const { data } = await authAxios.post('/api/admin/message', { message });
    if (data.success) {
      setSuccess(true)
      setSucessMsg(data.message)
    }
    console.log('data', data)
  }

  return (
    <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto flex flex-col'>
      <div>
        <h1 className='font-bold'>Send us a message</h1>
      </div>
      <div className="mt-3 p-3">
        <label htmlFor="Message" className="block text-sm font-sm leading-6 text-gray-900">
          Message
        </label>
        {success ?
          <>
            <p>{successMsg}</p>
          </>
          :
          <>
            <div className="mt-2">
              <textarea
                id="Message"
                name="Messsage"
                rows={3}
                className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:py-1.5 sm:text-sm sm:leading-6"
                defaultValue={''}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className='flex p-3 py-5 respon justify-around'>
              <div><p className="mt-3 text-sm leading-6 text-gray-600">Or email us at hello@wanderlustcard.com</p></div>
              <div className='py-3 lg:py-0'><button
                onClick={handleSubmit}
                className=" bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 "
              >
                Send message
              </button>
              </div>
            </div>
          </>
        }

      </div>
    </div>
  );
}

export default HostelHelp;