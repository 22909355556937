import React, { useEffect } from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import HostelSideBar from '../../component/hostelDashboard/HostelSideBar';
import { useSelector } from 'react-redux';

const HostelDashboard = () => {

  const { isAuth } = useSelector((state) => state.adminReducer);
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuth) {
      navigate('/hostel/login')
    }
  }, [isAuth])

  if (!isAuth) {
    return <Navigate to="/hostel/login" />
  }

  return (
    <div className="flex flex-row bg-gray-300 siderespon">

      <div className="lg:h-screen">
        <HostelSideBar />
      </div>

      <div className="w-full">
        {<Outlet />}
      </div>
    </div>
  )
}

export default HostelDashboard;