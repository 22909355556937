import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API } from '../../../Backend'
import moment from 'moment'

export default function Memberadded() {
    const [members, setMembers] = useState([])
    const [revenue, setRevenue] = useState(0)
    useEffect(() => {
        const getdata = async () => {
            try {
                const token = JSON.parse(localStorage.getItem('hostelInfo')).token

                const authAxios = axios.create({
                    baseURL: API,
                    headers: {
                        "Content-type": 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                const { data } = await authAxios.get('/api/admin/getreferre');
                setMembers(data.member);
                console.log('data', data.member[0].firtName)
                const reven = data.member.filter((i) => i.isReferralPaid === true)
                console.log('revenue', reven)
                const cost = reven.length * 5
                setRevenue(data.member.length*5)
            } catch (err) {

            }
        }

        getdata()
    }, [])
    console.log(members)
    return (
        <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto  '>
            <div className='flex flex-row justify-between'>
                <div>
                    <h1 className='font-bold '>Members added</h1>
                    <p className='text-sm font-light m-1'>Be sure to fill out your Payment Information in the Account tab to collect your revenue earned by members.</p>
                </div>
                <div className=''>
                    <button
                        type="submit"
                        className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                    >
                        Save
                    </button>
                </div>
            </div>

            <div className="flex mt-5 respon">
                <div className='w-1/3'>
                    <div>
                        <p className='text-center text-3xl font-bold text-blue-400'>{members.length > 0 ? members.length : 0}</p>
                        <p className='text-center'>Members added by you</p>
                    </div >
                    <div className='py-3'>
                        <p className='text-center text-3xl font-bold text-blue-400'>${revenue}</p>
                        <p className='text-center'>Total revenue earned</p>
                    </div>
                </div >
                {/* table */}
                < div className='w-4/4 ' >
                    <div className="flex flex-col ">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8 ">
                                <div className="overflow-hidden overscroll-x-auto">
                                    <table className="min-w-full border text-center text-sm font-light respon inline-table">
                                        <thead
                                            className="border-b font-medium text-black dark:border-neutral-500 dark:bg-neutral-900 ">
                                            <tr>

                                                <th scope="col" className=" px-6 py-4">Member name</th>
                                                <th scope="col" className=" px-6 py-4">Membership start date</th>
                                                <th scope="col" className=" px-6 py-4">Status</th>
                                            </tr>
                                        </thead>
                                        {
                                            members.length > 0 && members.map((mem, index) => (
                                                <tbody>
                                                    <tr className="border-b dark:border-neutral-500">

                                                        <td className="whitespace-nowrap  px-6 py-4">{mem.firstName !== null ? mem.firstName : mem?.userId[0]?.email}</td>
                                                        <td className="whitespace-nowrap  px-6 py-4">{mem.startDate !== null && moment(mem.startDate).format('DD-MM-YY')}</td>
                                                        {mem.subscriptionStatus === "InActive" ?
                                                            <td className="whitespace-nowrap  px-6 py-4 font-medium text-red-500">Not Active</td>
                                                            : <td className="whitespace-nowrap  px-6 py-4 font-medium text-green-500">{mem.subscriptionStatus}</td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            ))

                                            //     <tr className="border-b dark:border-neutral-500">

                                            //         <td className="whitespace-nowrap  px-6 py-4 ">Jacob</td>
                                            //         <td className="whitespace-nowrap  px-6 py-4">Thornton</td>
                                            //         <td className="whitespace-nowrap font-medium text-red-500  px-6 py-4">Not Active</td>
                                            //     </tr>

                                            // </tbody>
                                        }

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='text-right text-sm font-light underline'>
                            <p>export all members added</p>
                        </div>
                    </div>
                </div >

            </div >

        </div >
    )
}