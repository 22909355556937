import React from 'react'

export default function Customerreview({ review }) {
  console.log(review)
  return (

    <div className='p-2 px-2 flex flex-row justify-start w-full space-x-2 items-start'>
      <div className='py-1   text-white text-center w-1/12 '>
        <div className='bg-green-400 w-12 h-12 rounded-full flex justify-center items-center'>
          <p className='text-center'>{review?.review}</p>
        </div>
      </div>
      <div className='py-1 flex flex-col px-4 text-left w-11/12'>
        <div><p className='font-medium text-gray-400'>{review?.created}</p></div>
        <div><p className='w-full text-base'>{review?.desc}.</p></div>
      </div>
    </div>

  )
}
