import React, { useEffect, useState, useRef } from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LoadScript, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
// import {adminSignUp} from '../../redux/actions/HostelAction.js';
import { API } from '../../Backend'
import axios from 'axios'


const authAxios = axios.create({
    baseURL: API,
    headers: {
        "Content-type": 'application/json',
    }
});


const PartnerDetails = ({ addStep }) => {


    // const { hostelDetails, loading } = useSelector((state) => state.adminReducer);
    const [formErrors, setFormErrors] = useState({});
    const [dataError, setDataError] = useState(null)
    const [address, setAddress] = useState('');
    const [lat, setLat] = useState('')
    const [long, setLong] = useState('')
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        ownerPhone: '',
        hostelName: '',
        hostelAddress: '',
        website: '',
        hostelPhone: '',
        bookingOption: ''
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleChange = (e) => {
        if (formErrors) {
            setFormErrors({
                ...formErrors,
                [e.target.name]: ""
            })
        }
        if (e.target.name === 'website1' || e.target.name === 'whatsapp1') {
            setFormData({
                ...formData,
                bookingOption: e.target.name === 'website1' ? 'website' : 'whatsapp'
            })
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
        }



    }


    const handleSubmit = async (e) => {
        e.preventDefault();


        let formErrors = validateForm(formData);

        if (Object.keys(formErrors).length !== 0) {
            setFormErrors(formErrors);
            setIsSubmit(false);
        } else {
            try {
                // const { lat, lng } = response.results[0].geometry.location;

                // console.log(lat, lng);
                // setIsSubmit(true);
                // dispatch(adminSignUp(formData))
                const data1 = { ...formData, long: long, lat: lat }
                const { data } = await authAxios.post('/api/admin/signup', data1);
                console.log(data)
                if (data.success) {
                    console.log(data.message);
                    addStep();
                }

            } catch (err) {
                console.log(err)
                setDataError(err.response.data.message)
            }

        }
    }

    const validateForm = (values) => {
        let errors = {};
        let { firstName, lastName, email, ownerPhone } = values;

        if (!firstName) {
            errors.firstName = "Please enter your First Name"
        }

        if (!lastName) {
            errors.lastName = "Please enter your Last Name"
        }

        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email) {
            errors.email = "Please enter your email address";
        } else if (!emailRegex.test(email)) {
            errors.email = "Please enter a valid email address";
        }

        if (!ownerPhone) {
            errors.ownerPhone = "Please enter your Phone Number";
        } else if (ownerPhone.length !== 10) {
            errors.ownerPhone = "Please enter a valid phone number"
        }

        let { hostelName, hostelAddress, city, country, zipCode, website, bookingOption } = values;
        if (!hostelName) {
            errors.hostelName = "Please enter your Hostel Name";
        }
        if (!hostelAddress) {
            errors.hostelAddress = "Please enter your Hostel Address";
        }
        if (!website) {
            errors.website = "Please enter Hostel's website";
        }
        if (!bookingOption) {
            errors.bookingOption = "Plese Select booking option"
        }

        return errors;
    }

    const inputRef = useRef();


    const handlePlaceChanged = () => {
        const place = inputRef.current.getPlaces()[0];
        // console.log(place);
        if (place) {
            const addresses = place.formatted_address;
            const { lat, lng } = place.geometry.location;
            setLat(lat())
            setLong(lng())
            setFormData({
                ...formData,
                hostelAddress: addresses
            })
        }
    }

    // const { isLoaded, loadError } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c",
    //     libraries: ['places'],
    // });

    // if (!isLoaded) {
    //     return <div>Loading...</div>;
    // }
    // if (loadError) {
    //     return <div>Loading...</div>;
    // }
    // const inputs = () => {
    //     if (isLoaded) {
    //     console.log('qwwqw')
    // }}
    // console.log(isLoaded)
    let inputDatas;
    if (window.google && window.google.maps) {
        // Google Maps API is already loaded
        // No need to load it again
        inputDatas = (

            inputDatas = (
                // <LoadScript googleMapsApiKey="AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c" libraries={["places"]}>
                // {() => (
                <StandaloneSearchBox
                    onLoad={ref => (inputRef.current = ref)}
                    onPlacesChanged={handlePlaceChanged}
                >

                    <input
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

                        placeholder=""
                    />
                </StandaloneSearchBox>
                // )}
                // </LoadScript>
            )
        )
    } else {
        // Google Maps API is not loaded
        // You can proceed to load it using the LoadScript component or other methods
        inputDatas = (
            <LoadScript googleMapsApiKey="AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c" libraries={["places"]}>
                {() => (
                    <StandaloneSearchBox
                        onLoad={ref => (inputRef.current = ref)}
                        onPlacesChanged={handlePlaceChanged}
                    >

                        <input
                            type="text"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

                            placeholder=""
                        />
                    </StandaloneSearchBox>
                )}
            </LoadScript>
        )
    }

    return (
        <div className="flex-1">
            <div className="text-center">
                <div className='flex justify-center'>
                    <img src={Logo} width='100' alt="" />
                </div>
                <p className="mt-3 text-gray-500 dark:text-gray-300">Sign up to be Wanderlust Partner</p>
            </div>

            <div className="mt-3">

                <form onSubmit={handleSubmit} noValidate>
                    <div className="flex justify-between gap-1  respon">
                        <span className="w-2/2">
                            <label for="firstName" className="block text-xs font-semibold text-gray-600 uppercase">Firstname</label>
                            <input
                                type="text"
                                name="firstName"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.firstName && <p className="my-2 text-sm text-red-600">{formErrors.firstName}</p>}

                        </span>
                        <span className="w-1/2">
                            <label for="lastName" className="block text-xs font-semibold text-gray-600 uppercase">Lastname</label>
                            <input
                                type="text"
                                name="lastName"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.lastName && <p className="my-2 text-sm text-red-600">{formErrors.lastName}</p>}

                        </span>
                    </div>

                    <div className="flex justify-between gap-3 py-2  respon">
                        <span className="w-1/2">
                            <label for="email" className="block text-xs w-full font-semibold text-gray-600 uppercase">Email</label>
                            <input
                                type="email"
                                name="email"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.email && <p className="my-2 text-sm text-red-600">{formErrors.email}</p>}

                        </span>
                        <span className="w-full">
                            <label for="ownerPhone" className="block text-xs font-semibold text-gray-600 uppercase">Owner Phone Number</label>
                            <input
                                type="number"
                                name="ownerPhone"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.ownerPhone && <p className="my-2 text-sm text-red-600">{formErrors.ownerPhone}</p>}

                        </span>
                    </div>
                    <div className="flex justify-between gap-3 py-2  respon">
                        <span className="w-1/2">
                            <label for="hostelName" className="block mt-2 text-xs font-semibold text-gray-600  uppercase w-full">Hostel Name</label>
                            <input
                                type="text"
                                name="hostelName"
                                className="block flex border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.hostelName && <p className="my-2 text-sm text-red-600">{formErrors.hostelName}</p>}
                        </span>
                        <span className="w-full">
                            <label for="hostelAddress" className="block text-xs w-full font-semibold text-gray-600 uppercase">Hostel Address</label>
                            {inputDatas}
                            {formErrors && formErrors.hostelAddress && <p className="my-2 text-sm text-red-600">{formErrors.hostelAddress}</p>}

                        </span>
                    </div>

                    <label for="website" className="block mt-2 text-xs font-semibold text-gray-600  uppercase  ">Hostel Website</label>
                    <div className="mt-2  respon">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                            <input
                                type="text"
                                name="website"

                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                onChange={handleChange}
                            />
                        </div>
                        {formErrors && formErrors.website && <p className="my-2 text-sm text-red-600">{formErrors.website}</p>}

                    </div>
                    <label for="hostelPhone" className="block mt-2 text-xs font-semibold text-gray-600  uppercase">Hostel Phone Number (Whatsapp)</label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">EC</span>
                            <input
                                type="text"
                                name="hostelPhone"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                onChange={handleChange}
                            />
                            {formErrors && formErrors.hostelPhone && <p className="my-2 text-sm text-red-600">{formErrors.hostelPhone}</p>}

                        </div>
                    </div>
                    <div>
                        <label for="bookingOption" className="block mt-2 text-xs font-semibold text-gray-600  uppercase">Hostel takes reservations through:</label>
                        <div className="px-3">
                            <input type="radio" checked={formData.bookingOption === 'website' ? true : false} className="" name="website1" onChange={handleChange} />
                            <label htmlFor="" className='mt-2 text-xs font-semibold text-gray-600 uppercase'>Website / online Booking</label>
                        </div>
                        <div className="px-3">
                            <input type="radio" checked={formData.bookingOption === 'whatsapp' ? true : false} className="" name="whatsapp1" onChange={handleChange} />
                            <label htmlFor="" className='mt-2 text-xs font-semibold text-gray-600 uppercase'>Whatsapp</label>
                        </div>
                        {formErrors && formErrors.bookingOption && <p className="my-2 text-sm text-red-600">{formErrors.bookingOption}</p>}

                    </div>

                    <button type="submit" className="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-blue-300 shadow-lg focus:outline-none">Sign up</button>
                    {dataError !== null && dataError}
                </form>
                <p className="mt-6 text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"><Link to='/hostel/login'>Sign In</Link></p>
            </div>
        </div>
    )
}

export default PartnerDetails;

