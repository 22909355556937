import React from 'react'
import Youroffers from './Profile/Youroffers'
import Hosteldetail from './Profile/Hosteldetail'
import Addmember from './Profile/Addmember'
import Memberadded from './Profile/Memberadded'


const HostelProfile = () => {
  return (
    <>
     <Youroffers/>
     <Hosteldetail />
     <Addmember />
     <Memberadded />

    </>
  )
}

export default HostelProfile