import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Logo from '../Assets/img/logo.png';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/actions/UserAction';
import { Link } from 'react-router-dom';


const navigation = [
  { name: 'Find Hostels', href: '/', current: true },
  { name: 'How it works', href: '/howitworks', current: false },
  { name: 'Pricing', href: '#', current: false },
  { name: 'About Us', href: '/about', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Navbar() {
  const { isAuth, user } = useSelector((state) => state.userReducer);
  const [userAvator, setUserAvator] = useState('');
  const dispatch = useDispatch();

  function handleSignIn() {

    setUserAvator({
      avatorUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    });

  };

  const handleLogOut = () => {
    const locally = localStorage.getItem('token')
    const session = sessionStorage.getItem('token');
    if (session) {
      sessionStorage.clear()
    }
    dispatch(logout())


  }

  return (
    <Disclosure as="nav" className="bg-gray-100">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src={Logo}
                    alt="Wanderlust"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={Logo}
                    alt="Wanderlust"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current ? ' text-black' : 'text-gray-700 ',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}

                     <Link
                      target="_blank"
                        to={'https://wanderlustcardblog.wordpress.com/'}
                        className={classNames( 'text-gray-700 px-3 py-2 rounded-md text-sm font-medium'
                        )}
                      >
                        {"Blogs"}
                      </Link>

                  </div>
                </div>
              </div>
              <div>
                <h1 className='hostelowner'><Link to="/hostel/signup">Hostel Owner</Link></h1>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {isAuth ? (
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.image !== undefined ? user.image : userAvator.avatorUrl}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/accountinfo"
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >
                                Your Account
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleLogOut}
                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                              >Sign Out

                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <div className="space-y-4 py-5 px-1 ">
                    <Link to={'/signin'}
                      className=" items-center justify-center border-4 border-grey-500 px-6 py-2 m-2 text-base font-mediumuppercase font-semibold  bg-blue-400  text-white shadow-md" onClick={handleSignIn}
                    >
                      Sign In
                    </Link>
                    {/* <Link to={'/'}
                      className=" p-2 items-center justify-center   border-4 border-grey-500 px-3 py-2 text-base font-mediumuppercase font-base bg-blue-400 text-white shadow-md invisible" onClick={handleSignIn}
                    >
                      Start free trail
                    </Link> */}

                  </div>

                )}

              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden text-black">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'text-gray-600' : 'text-gray-600  ',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"><Link to="/hostel/signup">Hostel Owner</Link></button>
            </div>

          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
};
