import React from 'react'

const HostelPassword = () => {
  return (
    <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto '>
      <div>
        <h1 className='font-bold'>Reset Password</h1>
      </div>
      <div className="text-center p-8">
        <p>Password reset flow</p>
      </div>
    </div>
  );
}

export default HostelPassword