import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../redux/actions/UserAction'
import { useParams, useNavigate } from 'react-router-dom'

const Reset = () => {
    const { userId, token } = useParams()
    const { message, error, loading } = useSelector((state) => state.forgotReducer);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loginerror, setLoginError] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    // error states
    const [displayError, setDisplayError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    // success state
    const [successfull, setSuccessfull] = useState("")

    const handleChange = () => {

    }

    const handleSubmit = () => {
        if (password.trim().length === 0 || confirmPassword.trim().length === 0) {
            displayErrorFunction("Please fill all the fields")
        } else if (password !== confirmPassword) {
            console.log(password, confirmPassword)
            displayErrorFunction("Password not matched with Confirm Password")
        } else {
            dispatch(resetPassword({ token, password, confirmPassword, userId }))
                .then(res => {
                    console.log(res)
                    if (!res.success) {
                        displayErrorFunction(res.message)
                    } else {
                        setSuccessfull(res.message);
                        setTimeout(() => {
                            navigate('/signin')
                        }, 3000)
                    }
                })
        }
    }

    const displayErrorFunction = (message) => {
        setErrorMessage(message)
        setDisplayError(true)
        setTimeout(() => {
            setDisplayError(false)
            setErrorMessage("")
        }, 3000);
    }

    return (
        <div>
            <div className="flex items-center h-96">
                <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-sm m-auto">

                    <div className="mb-4">
                        <label
                            className="block font-bold mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            className="border border-gray-400 p-2 w-full"
                            id="password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label
                            className="block font-bold mb-2"
                            htmlFor="confirmpassword"
                        >
                            Confirm Password
                        </label>
                        <input
                            className="border border-gray-400 p-2 w-full"
                            id="confirmpassword"
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    {!displayError ? <></> : <span>{errorMessage}</span>}
                    {successfull !== "" ? <span>{successfull}</span> : <></>}
                    <div className="flex space-x-4 justify-center">
                        <button className="px-6 h-12 uppercase font-semibold tracking-wider 
                        border-2 border-grey bg-green-300 bg-teal-400 text-black " type="submit" onClick={handleSubmit}>
                            Forget Password
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Reset;