import * as React from "react";
import Clientsidebar from "../component/Clientsidebar";
import {Outlet, Navigate} from 'react-router-dom';


export default function Accountpage() {
  return (  
    <div className="flex flex-row bg-gray-300 siderespon">

    <div className="lg:h-screen">
      <Clientsidebar />
    </div>

    <div className="w-full">
      {<Outlet/>}
    </div>
  </div>
  );
}
