import React, { useEffect } from 'react'
import axios from 'axios'
import { API } from '../Backend'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../redux/actions/UserAction'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
const Success = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.userReducer)
    useEffect(() => {
        dispatch(getUser())



        const token = localStorage.getItem('token')
        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        const setPayment = async () => {

            const { data } = await authAxios.put(`/update`)
            console.log(data)
            if (data.success) {
                navigate('/accountinfo/planbilling')

            }
        }
        setPayment()
    }, [])
    return (
        <div>
            <div className='py-28'>
                <div className="flex items-center h-96">
                    <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-sm m-auto">
                        <div className="flex space-x-4 justify-center mb-4">
                            <img src="/check.png" alt="check" />
                        </div>
                        <div className="mb-4">
                            Your subscription has been activated. it will end on {moment(user.endDate).format('DD-MM-YY')}
                        </div>
                        <div className="mb-4">

                        </div>

                        <div className="flex space-x-4 justify-center">

                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}

export default Success