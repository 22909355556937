import {
    GET_HOSTEL_FAIL,
    GET_HOSTEL_REQUEST,
    GET_HOSTEL_SUCCESS
} from '../constants/UserConstant';

import {
    ADMIN_SIGNUP_REQUEST,
    ADMIN_SIGNUP_SUCCESS,
    ADMIN_SIGNUP_FAIL,
    ADMIN_SIGNIN_FAIL,
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNOUT,
    ADMIN_UPDATE_REQUEST,
    ADMIN_UPDATE_FAIL,
    ADMIN_UPDATE_SUCCESS
} from '../constants/hostelConstant';

const initialState = {
    loading: false,
    hostel: [],
    error: null,
    message: null
}


export const hostelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HOSTEL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                hostel: [],
                message: null
            }

        case GET_HOSTEL_SUCCESS:
            return {
                ...state,
                loading: false,
                hostel: action.payload.hostels,
                message: action.payload.message,
                error: null
            }

        case GET_HOSTEL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                message: null
            }
        default:
            return state;
    }
}

const adminInitialState = {
    loading: false,
    hostelDetails: localStorage.getItem('hostelInfo') ? JSON.parse(localStorage.getItem('hostelInfo')) : null,
    message: null,
    error: null,
    isAuth: localStorage.getItem('hostelInfo') ? true : false
}

export const adminReducer = (state = adminInitialState, action) => {
    switch (action.type) {
        case ADMIN_SIGNIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                hostelDetails: null,
                message: null
            }

        case ADMIN_SIGNIN_FAIL:
        case ADMIN_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                message: null
            }

        case ADMIN_SIGNIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                hostelDetails: action.payload,
                isAuth: true
            }



        case ADMIN_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case ADMIN_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                hostelDetails: action.payload.owner,
                message: action.payload.message,
                isAuth: true
            }



        case ADMIN_SIGNOUT:
            return {
                ...state,
                isAuth: false,
                hostelDetails: null,
                loading: false
            }

        default:
            return state;
    }
}