import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminupdate } from '../../../redux/actions/HostelAction'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Youroffers() {
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        amount: '',
        activity: ''
    })
    const [offerArr, setOfferArr] = useState([])
    const { hostelDetails } = useSelector((state) => state.adminReducer.hostelDetails)
    const [err, setErr] = useState(null)
    useEffect(() => {
        if (hostelDetails !== undefined) {
            setOfferArr([...hostelDetails.offers])
        }
    }, [hostelDetails])


    const handleSubmit = async () => {
        const offers = offerArr;
        dispatch(adminupdate({ offers: offers })).then((res) => {
            if (res.success) {
                toast.success("Offers Updated successfully")
            }
        })
    }

    const handleCreateOffer = () => {
        if (offerArr.length === 6) {
            displayErrorFunction('cannot create more than 6 offers')
        } else if (details.amount.length !== 0 && details.amount.length !== 0) {
            const str = details.amount + " " + details.activity
            const arr = [...offerArr, str]
            setOfferArr(arr)
            setDetails({ amount: '', activity: '' })
        }
    }

    const displayErrorFunction = (message) => {
        setErr(message)
        setTimeout(() => {
            setErr(null)
        }, 3000);
    }

    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const removeOffers = (e, offer) => {
        e.preventDefault()
        const arr = offerArr.filter(off => off !== offer);
        setOfferArr(arr)
    }
    return (
        <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto  '>
            <div className='flex flex-row justify-between '>
                <div>
                    <h1 className='font-bold '>Your Offers</h1>
                </div>
                <div className=''>
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                    >
                        Save
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-3 respon">
                <div className='p-2 m-4 grid gap-1'>

                    {
                        offerArr.map((offer, index) => (
                            <p className='text-sm' key={index}>
                                <input type="radio" name="" id="" />
                                {offer} <span onClick={(e) => removeOffers(e, offer)}>close</span>
                            </p>
                        ))
                    }
                </div>

                {/* <div className='p-2 m-4 grid gap-1'>
                    <p className='text-sm'><input type="radio" name="" id="" /> 10% off stay</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> 10% off Day tours</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> 10% off Breakfast</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> 10% off Dinner</p>
                </div>
                <div className='p-2 m-4 grid gap-1'>
                    <p className='text-sm'><input type="radio" name="" id="" /> 1 free drink (beer or cocktail)</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> 20% off Bar Crawl</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> $10 off Cuyabeno Jungle Tour</p>
                    <p className='text-sm'><input type="radio" name="" id="" /> 1 night free, if you stay 7 nights</p>
                </div> */}
                <div className='p-1'>
                    <h1 className='font-bold'>Add custom offer</h1>
                    <div className="mt-2  respon ">
                        <label htmlFor="">Amount</label>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="amount"
                                onChange={handleChange}
                                value={details.amount}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />
                            <span className="flex select-none items-center p-1 text-gray-500 sm:text-sm"> % </span>
                        </div>
                    </div>
                    <div className="mt-2  respon">
                        <label htmlFor="">Activity</label>
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type="text"
                                name="activity"
                                onChange={handleChange}
                                value={details.activity}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                            />

                        </div>
                    </div>
                    <div className='text-center py-5'>
                        <button
                            type="submit"
                            onClick={handleCreateOffer}
                            className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                        >
                            Create Offer
                        </button>

                    </div>
                    {err !== null && <p className='text-red-600'>{err}</p>}

                </div>
                <ToastContainer />

            </div>

        </div>
    )
}
