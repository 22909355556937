import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    ACTIVATE_ACCOUNT_FAIL,
    ACTIVATE_ACCOUNT_SUCCESS,
    ACTIVATE_ACCOUNT_REQUEST,
    LOGOUT_SUCCESS,
    RESET,
    SIGNUP_EMAIL_REQUEST,
    SIGNUP_EMAIL_SUCCESS,
    SIGNUP_EMAIL_FAIL,
    UPDATE_ACCOUNT_INFO_FAIL,
    UPDATE_ACCOUNT_INFO_REQUEST,
    UPDATE_ACCOUNT_INFO_SUCCESS,
    GET_PAYMENT_FAIL,
    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
    CANCEL_MEMBERSHIP_FAIL,
    CANCEL_MEMBERSHIP_REQUEST,
    CANCEL_MEMBERSHIP_SUCCESS,
    GET_HOSTEL_FAIL,
    GET_HOSTEL_REQUEST,
    GET_HOSTEL_SUCCESS
} from '../constants/UserConstant'
import { API } from '../../Backend'
import axios from 'axios'
import { persistor } from "../Store.js"

export const getUser = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PAYMENT_REQUEST });

        const token = localStorage.getItem('token')
        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        const { data } = await authAxios.get(`/success`);
        console.log(data)
        dispatch({ type: GET_PAYMENT_SUCCESS, payload: data });
        return data
    } catch (err) {
        dispatch({ type: GET_PAYMENT_FAIL, payload: err.response.data.message })
        return err.response.data.message
    }
}

export const gethostel = () => async (dispatch) => {
    try {
        dispatch({ type: GET_HOSTEL_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.get(`/api/customer/gethostels`);
        console.log(data)
        dispatch({ type: GET_HOSTEL_SUCCESS, payload: data });
        return data
    } catch (err) {
        dispatch({ type: GET_HOSTEL_FAIL, payload: err.response.data.message });
        return err.response.data
    }
}

export const login = (user) => async (dispatch) => {
    console.log('a')
    try {
        dispatch({ type: LOGIN_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post('/api/user/signin', user)
        console.log(data)
        localStorage.setItem('user', JSON.stringify(data.user))

        dispatch({ type: LOGIN_SUCCESS, payload: data })
        return data

    } catch (error) {
        dispatch({ type: LOGIN_FAIL, payload: error.response.data.message })
        return error.response.data
    }
}

export const signup = (user) => async (dispatch) => {
    try {
        console.log(user)
        dispatch({ type: !user.password ? SIGNUP_EMAIL_REQUEST : REGISTER_USER_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post('/api/user/signup', user)
        if (user.password) {
            localStorage.setItem('user', JSON.stringify(data.user))
        }

        dispatch({ type: !user.password ? SIGNUP_EMAIL_SUCCESS : REGISTER_USER_SUCCESS, payload: data })

        return data


    } catch (error) {
        dispatch({ type: !user.password ? SIGNUP_EMAIL_FAIL : REGISTER_USER_FAIL, payload: error.response.data.message })
        return error.response.data
    }
}


export const logout = () => async (dispatch) => {
    try {
        localStorage.clear();
        await persistor.purge();
        dispatch({ type: RESET });

        dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
        // dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
    }
};

export const accountInfoUpdate = (user) => async (dispatch) => {
    try {
        console.log(user)

        dispatch({ type: UPDATE_ACCOUNT_INFO_REQUEST })
        const token = localStorage.getItem('token')
        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        const { data } = await authAxios.patch('/api/customer/updateaccount', user);
        localStorage.setItem('user', JSON.stringify(data.user))

        console.log('data', data)
        dispatch({ type: UPDATE_ACCOUNT_INFO_SUCCESS, payload: data })

    } catch (error) {
        dispatch({ type: UPDATE_ACCOUNT_INFO_FAIL, payload: error.response.data.message });
        return error.response.data
    }
}

export const hostelimage = (user) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ACCOUNT_INFO_REQUEST })
        const token = localStorage.getItem('token')


        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'multipart/form-data',
                Authorization: `Bearer ${token}`

            }
        });

        const { data } = await authAxios.patch('/api/customer/uploadimage', user);
        console.log('image', data)
        dispatch({ type: UPDATE_ACCOUNT_INFO_SUCCESS, payload: data });
        return data;
    } catch (err) {
        dispatch({ type: UPDATE_ACCOUNT_INFO_FAIL, payload: err.response.data.message });
        return err.response.data
    }
}


export const forgotpassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post('/api/user/forgetpassword', { email })

        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message })

        return data
    } catch (error) {
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: error.response.data.message })
        return error.response.data
    }
}

export const resetPassword = (userData) => async (dispatch) => {
    try {
        const { token, password, userId, confirmPassword } = userData

        dispatch({ type: RESET_PASSWORD_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.put(
            `/api/user/resetpassword/${userId}/${token}`, { password, confirmpassword: confirmPassword }
        );
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
        return data

    } catch (error) {
        dispatch({ type: RESET_PASSWORD_FAIL, payload: error.response.data.message })
        return error.response.data
    }
}

export const activateAccount = (userData, token) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVATE_ACCOUNT_REQUEST });

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post(`/api/user/activate/${token}`, userData)
        console.log(data)
        localStorage.setItem('user', JSON.stringify(data.user))

        dispatch({ type: ACTIVATE_ACCOUNT_SUCCESS, payload: data })
        return data

    } catch (err) {
        dispatch({
            type: ACTIVATE_ACCOUNT_FAIL, payload: err.response.data.message
        })
        return err.response.data
    }
}

export const cancelMembership = (id) => async (dispatch) => {
    try {
        console.log('id', id)
        dispatch({ type: CANCEL_MEMBERSHIP_REQUEST });

        const token = localStorage.getItem('token')
        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        const { data } = await authAxios.post('/api/subscription/cancel', { subscriptionId: id });

        dispatch({ type: CANCEL_MEMBERSHIP_SUCCESS, payload: data })
        return data
    } catch (err) {
        dispatch({ type: CANCEL_MEMBERSHIP_FAIL, payload: err.response.data.message })
        return err.response.data
    }
}