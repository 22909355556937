import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { accountInfoUpdate } from '../../redux/actions/UserAction.js';
import qrimg from '../../Assets/img/logo.png'
import QRCode from "react-qr-code";
import { useNavigate } from 'react-router-dom';
import { hostelimage } from '../../redux/actions/UserAction.js';

export default function Account() {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const { user, isAuth } = useSelector((state) => state.userReducer);
  const [details, setDetails] = useState()
  const [email, setEmail] = useState('')
  const [image, setImage] = useState(null)
  const [showImage, setShowImage] = useState(null)
  const dispatch = useDispatch()

  console.log(user)
  const navigate = useNavigate()


  useEffect(() => {
    if (!isAuth) {
      navigate('/')
    }
  }, [isAuth])

  useEffect(() => {
    if (user !== null) {
      setDetails({ firstName: user.firstName !== undefined ? user.firstName : '', lastName: user.lastName !== undefined ? user.lastName : '' })
      setEmail(user.userId.email)
      if (user.image !== undefined) {
        setShowImage(true)
        setImage(user.image)
      }
    }
  }, [user])


  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(accountInfoUpdate(details)).then((res) => {
      console.log('res,res', res)
    })
    setDetails({ firstName: '', lastName: '' })
  }


  const handleImage = (e) => {
    console.log(e.target.files[0])
    const path = e.target.files[0]
    dispatch(hostelimage({ image: path })).then((res) => {

    })
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModal1 = () => {
    setModal1(!modal1);
  };

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }


  return (
    <div className='container mx-auto m-6 bg-white'>
      <div className='text-center border-4 p-4   bg-gradient-to-r from-purple-600 to-green-200'><button className=' text-white' onClick={toggleModal}>View Your QR Code</button></div>
      <div className="flex border justify-center relative ">
        {modal && (
          <div className="modal">
            <div onClick={toggleModal} className="overlay"></div>
            <div className="modal-content bg-black">
              <div className='flex flex-row p-5 justify-center'>
                <div className='p-2 bg-white flex py-10'>
                  {/* <img src={qrimg} width="250" alt="" /> */}
                  <QRCode value={`https://app.wanderlustcard.com/customer/subscription/${user._id}`} size={150} />
                </div>
                <div className='p-2 py-5 bg-white'>
                  <div className=' text-center '>
                    <div className='justify-center flex'><img src={qrimg} width="200" alt="" /></div>
                    <h1>{user.firstName !== undefined ? user.firstName : 'User'}</h1>
                    {/* <p>Member Number : 1831497</p> */}
                    {/* <p className='font-bold'>Status : <span className='font-bold text-green-400'>Active</span></p> */}
                    {/* <p>Member Number : 1831497</p> */}
                    <p className='font-bold'>Status : <span className='font-bold text-green-400'>{user.subscriptionStatus}</span></p>
                  </div>
                </div>
              </div>
              <button className="close-modal p-1 text-white cursor-pointer" onClick={toggleModal}>
                CLOSE
              </button>
            </div>
          </div>
        )}
        <div className='flex flex-row respon p-2 bg-white'>

          <div className='p-4'>
            <div className='py-5 '>
              <div className="mt-5 md:col-span-2 md:mt-0">
                <h1 className='font-bold px-4'>Profile Information</h1>
                <div>
                  <div className="overflow-hidden ">
                    <div className="bg-white px-4 py-5 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            First name
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            id="first-name"
                            autoComplete="given-name"
                            value={user?.firstName}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                            Last name
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            id="last-name"
                            autoComplete="family-name"
                            value={user?.lastName}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-4">
                          <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                            Email address
                          </label>
                          <input
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            value={email}
                            disabled
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>

                        {/* <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                            Member ID
                          </label>
                          <input
                            type="number"
                            name=""
                            id="membeid"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div> */}
                        <div>
                          <button className='bg-blue-400 px-6 py-2 text-sm text-white' onClick={handleSubmit}>save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' respon lg:m-12 p-1 w-50 '>
            {
              showImage !== null ?
                <img src={image} width={150} alt="user-image" /> :
                <img src="https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-placeholder-png-image_3918418.jpg" width={150} alt="userimage" />

            }
            <input type="file" onChange={handleImage} accept=".jpg, .jpeg, .png" />
          </div>
        </div>

      </div>


      <div className='p-3 border flex justify-center w-full bg-white'>

        <div className="flex flex-col ">
          <h1 className='font-bold text-lg p-1'>Delete Account</h1>
          {/* modal */}
          {modal1 && (
            <div className="modal">
              <div onClick={toggleModal1} className="overlay"></div>
              <div className="modal-content bg-white">
                <h2 className='font-bold p-3'>Delete your account</h2>
                <p className='text-sm font-light'>
                  This is permanent and cannot be undone. Once you delete your account, all your data will be permanently removed
                </p>
                <div className='p-2 flex flex-row justify-end '>
                  <div className='px-2'>
                    <button className="px-2 bg-gray-400 px-5 py-2 text-sm text-white" onClick={toggleModal1}>
                      Back
                    </button>
                  </div>
                  <button type="button" className=" bg-red-400 px-2 py-2 text-sm text-white " onClick={toggleModal1}>Delete Account</button>
                </div>
              </div>
            </div>
          )}

          <div><p className='w-full text-sm'>This is permanent and cannot be undone. Once you delete your account, all your data will be permanently removed.</p></div>
        </div>
        <div className='py-4 px-3'><button type="button" className=" bg-red-400 px-2 py-2 text-sm text-white " onClick={toggleModal1}>Delete Account</button></div>
      </div>
    </div>
  )
}
