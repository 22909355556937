export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const RESET = 'RESET'
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";


export const SIGNUP_EMAIL_SUCCESS = 'SIGNUP_EMAIL_SUCCESS'
export const SIGNUP_EMAIL_REQUEST = 'SIGNUP_EMAIL_REQUEST'
export const SIGNUP_EMAIL_FAIL = 'SIGNUP_EMAIL_FAIL'

export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS'
export const ACTIVATE_ACCOUNT_FAIL = 'ACTIVATE_ACCOUNT_FAIL'
export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVATE_ACCOUNT_REQUEST'

export const UPDATE_ACCOUNT_INFO_SUCCESS = 'UPDATE_ACCOUNT_INFO_SUCCESS'
export const UPDATE_ACCOUNT_INFO_FAIL = 'UPDATE_ACCOUNT_INFO_FAIL'
export const UPDATE_ACCOUNT_INFO_REQUEST = 'UPDATE_ACCOUNT_INFO_REQUEST'

export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS'
export const GET_PAYMENT_FAIL = 'GET_PAYMENT_FAIL'
export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST'

export const CANCEL_MEMBERSHIP_REQUEST = 'CANCEL_MEMBERSHIP_REQUEST'
export const CANCEL_MEMBERSHIP_FAIL = 'CANCEL_MEMBERSHIP_FAIL'
export const CANCEL_MEMBERSHIP_SUCCESS = 'CANCEL_MEMBERSHIP_SUCCESS'

export const GET_HOSTEL_REQUEST = 'GET_HOSTEL_REQUEST'
export const GET_HOSTEL_SUCCESS = 'GET_HOSTEL_SUCCESS'
export const GET_HOSTEL_FAIL = 'GET_HOSTEL_FAIL'



