import React, {useState} from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"

const SetPassword = ({details, addStep, handleChange}) => {

  const [passwordError, setPasswordError] = useState('');

  const handlePasswordInput = (e) => {
    setPasswordError('');
    handleChange(e);
  }

  const handleStep = () => {
    let passwordError = validatePassword(details.password);
    if(!passwordError){
      addStep();
    }else {
      setPasswordError(passwordError);
    }
  }

  const validatePassword = (value) => {
    let error = '';
    if(!value){
      error = 'Please enter a Password';
    }else if(value.length < 8){
      error = 'Your Password should consist of 8 characters';
    }
    return error;
  }


  return (
    <div className="flex-1">
        <div className="text-center">
            <div className='flex justify-center'>
            <img src={Logo} width='100' alt="" />
            </div>
            <p className="mt-3 text-black text-4xl">You're In!</p>
        </div>
        <div className="mt-8">
            <p className='text-center font-medium py-2 '>Thank you for signing up to be a Wanderlust partner, we’re excited to have you onboard! </p>
            <p className='text-center py-2 font-medium'>Check out <span className='underline'> this video on how to use your account.</span></p>
            <p className='text-center font-bold'>Create your password:</p>
            <input type="password"  id="password" name="password" value={details.password} onChange={handlePasswordInput} placeholder="Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />  
            {passwordError && <p className="my-2 text-red-600">{passwordError}</p>}

        </div>

        <div className='py-4'>
          <button onClick={handleStep} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">Create password & Next</button>
        </div>
        
    </div>
  )
}

export default SetPassword