import React, { useState, useEffect } from 'react'
import Logo from '../Assets/img/logo.png';
import img1 from '../Assets/img/banner.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { login } from '../redux/actions/UserAction.js';


export default function SignIn() {
  const { isAuth, loading } = useSelector((state) => state.userReducer);
  const [remember, setRemember] = useState(false)

  // error states
  const [displayError, setDisplayError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [data, setData] = useState({ email: '', password: '' });
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const { email } = data
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  }

  const handleSubmit = () => {

    const userdetails = { userData: data, userType: 'member' }
    const { email, password } = data
    if (email.trim().length === 0 || password.trim().length === 0) {
      displayErrorFunction("Please fill all the fields")
    } else if (!emailValidation()) {
      displayErrorFunction("Please enter a valid email")
    } else {
      dispatch(login(userdetails))
        .then((res) => {
          if (res.success) {
            localStorage.setItem('token', res.token)
          } else {
            console.log(res)
            displayErrorFunction(res.message)
          }
        })
    }
  }

  const displayErrorFunction = (message) => {
    setErrorMessage(message)
    setDisplayError(true)
    setTimeout(() => {
      setDisplayError(false)
      setErrorMessage("")
    }, 3000);
  }


  return (
    <>

      <div>
        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                  <div>
                    <img
                      className="mx-auto h-12 w-auto"
                      src={Logo}
                      alt="wanderlust"
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold  text-gray-900">
                      Sign in to your account
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                      Or{' '}
                      <Link to={"/signup"} className="font-medium text-blue-400 hover:text-indigo-500">
                        Start your subscription
                      </Link>
                    </p>
                  </div>
                  <div className="mt-8 space-y-6">
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="-space-y-px rounded-md shadow-sm">
                      <div>
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="email-address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          onChange={handleChange}
                          className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Email address"
                        />
                      </div>
                      <div>
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          onChange={handleChange}
                          className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Password"
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="rememberMe"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          onChange={() => setRemember(!remember)}
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm">
                        <a href="/forget" className="font-medium text-blue-400 hover:text-indigo-500">
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="group relative flex w-full justify-center border-4  py-2 px-4 text-sm font-medium uppercase font-semibold border-grey-400 bg-blue-300  text-white shadow-md "
                      >
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">

                        </span>
                        {loading ? 'loading...' : 'Sign in'}
                      </button>
                    </div>
                    {!displayError ? <></> : <span className='text-red-600'>{errorMessage}</span>}
                  </div>
                </div>
              </div>
              <img src={img1} alt="" className='w-[48rem] max-w-none rounded-xl   sm:w-[57rem] md:-ml-4 lg:-ml-0' />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
