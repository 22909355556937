import React from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"

const SignUpSuccess = () => {
  return (
    <div className="flex-1">
        <div className="text-center">
            <div className='flex justify-center'>
            <img src={Logo} width='100' alt="" />
            </div>
            
            <p className="mt-3 text-black text-2xl">Thank you!</p>
        </div>
        <div className="mt-8">
            <p className='text-center font-medium '>Thanks for submitting to be a Wanderlust partner. We’ll look over your information and contact you via email once approved.</p>
            <p className='text-center py-2 font-medium'>If you have any questions, contact us at hello@wanderlustcard.com</p>
        </div>
    </div>
  )
}

export default SignUpSuccess