import React,{useState} from 'react'
import footerimg from "../Assets/img/logo/W light transparent.png"
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API } from '../Backend'

export default function Footer() {
	const [SUBSCRIBE, setSUBSCRIBE] = useState('')
    const [displayError, setDisplayError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState("")
	
	const authAxios = axios.create({
		baseURL: API,
		headers: {
			"Content-type": 'application/json',
		}
	});

    const displayErrorFunction = (message) => {
        setErrorMessage(message)
        setDisplayError(true)
        setTimeout(() => {
            setDisplayError(false)
            setErrorMessage("")
        }, 3000);
    }
	const createSUBSCRIBE=async()=>{
		if(SUBSCRIBE){
		await authAxios.post('/api/user/subscribe', { email:SUBSCRIBE }).then(res => {
			setSuccess(res.message)
			console.log(res,"nnnnn")

		}).catch(({response})=>{
			console.log(response,"asasas")
			displayErrorFunction(response?.data?.message)
		})
		}
	}
  return (
    <div >
        <footer className="px-1 divide-y bg-white-800 text-black">
	<div className="container flex flex-col justify-between py-7 mx-auto space-y-8 lg:flex-row lg:space-y-0">

		<div className="lg:w-1/5 container mx-auto">
			<a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
			<div className="p-1 ">
				<img width="100" src={footerimg} />
			</div>
			</a>
		</div>

		<div className="grid grid-cols-2 text-sm gap-x-2 gap-y-8 lg:w-3/3 sm:grid-cols-4">
			<div className="space-y-3">
				<h3 className="tracking-wide uppercase font-bold text-black">Product</h3>
				<ul className="space-y-1">
					<li>
						<a rel="noopener noreferrer" href="#">How it works </a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">Pricing</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">FAQ</a>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<h3 className="tracking-wide uppercase font-bold text-black">Contact Us</h3>
				<ul className="space-y-1">
					<li>
						<a rel="noopener noreferrer" href="#">Member</a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">Hostel Owner</a>
					</li>
				</ul>
			</div>
			<div className="space-y-3">
				<h3 className="uppercase font-bold text-black">About Us</h3>
				<ul className="space-y-1">
					<li>
						<a rel="noopener noreferrer" href="#"><Link to='/About'>Our story</Link></a>
					</li>
					<li>
						<a rel="noopener noreferrer" href="#">Blog</a>
					</li>
					{/* <li>
						<a rel="noopener noreferrer" href="#">Legal</a>
					</li> */}
				</ul>
			</div>
			<div className="space-y-3">
				<div className="uppercase font-bold text-black">Known to cause some serious Wanderlust</div>
				<div className="flex justify-start space-x-3">
					<p>Subscribe to our newsletter for travel inspo, community events,and exclusive deals</p>
					<div className='gap-3 flex flex-col p-2 overflow-hidden'>
                    
				</div>
				
			</div>
			<div className='flex flex-col'>
			<div className='flex flex-row'>
                      <div>
                        <input value={SUBSCRIBE} onChange={(e)=>setSUBSCRIBE(e.target.value)} class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline" type="text" placeholder="Email"  />
                      </div>
                      <div className='mx-1 '>
                        <button 
						onClick={()=>createSUBSCRIBE()}
                          className="flex flex-row items-center text-xs uppercase border-4 border-grey-400 bg-blue-400 p-1 text-white shadow-md">
                          Subscribe
                        </button>
                      </div>
			 </div>
			 {!displayError ? <></> : <span className=''>{errorMessage}</span>}
			 {success !== "" ? <span>{success}</span> : <></>}
			 
         </div>
					
			</div>
		</div>
	</div>
	<div className="py-6 text-sm text-center text-gray-400">© 2023 Wanderlust Card Inc. All rights reserved.</div>
</footer>
    </div>
  );
}
