import React, { useState } from 'react';
import Mapsearch from '../component/Mapsearch'
import { useDispatch } from 'react-redux'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { HiMapPin } from "react-icons/hi2";
const people = [
  {
    id: 1,
    name: 'China',

  },
  {
    id: 2,
    name: 'India',

  },
]


function Topbanner(props) {
  const [email, setEmail] = useState('')
  const { onSignup } = props


  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const [selected, setSelected] = useState(people[1])
  const [success, setSucess] = useState('')
  const dispatch = useDispatch()

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  React.useEffect(() => {
    console.log(props)
  }, [])

  const handleSubmit = () => {
    onSignup(email)

    // setShowMessage(true);
  }

  return (
    <section className='topbanner top-0'>
      <div className='container-xl '>
        <div className="flex justify-start">
          <div >
            <div className='membership-box drop-shadow-md md:max-w-2xl md:pb-16 px-14 line-height'>
              <h1 className='text-2xl lg:text-4xl font-bold uppercase text-green-500 p-2 '><span className='text-white'>Experience More,</span> FOR LESS</h1>
              <h3 className='text-white font-medium text-2xl'>Your <span className='text-blue-400'>W</span> Benefits</h3>
              <ul className='text-m text-white'>
                {props.List}
              </ul>
              <div className='py-5'>

                <h3 className='text-white font-medium text-2xl'><span className='text-blue-400'>W</span> Community Difference :</h3>
                <p className='text-white font-medium text-sm'>Every time you check-in we we'll donate to a local community organization</p>
              </div>

              <div className='p-1 lg:pt-0 xl:pt-0  '>
                {props?.showMessage ?
                  <p className='text-white bg-green-400 p-2 text-center'>Success! Check your email to confirm your subscription
                    Browse exclusive Wanderlust deals below.</p>
                  :
                  <div className='gap-3 flex flex-col p-2 overflow-hidden'>
                    <div className='flex flex-row respon'>
                      <div >
                        <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                    focus:outline-none focus:shadow-outline" type="email" value={email} placeholder="Email" onChange={handleChange} />
                      </div>
                      <div className='px-6 text-base py-1 '>
                        <button onClick={handleSubmit}
                          className="flex flex-row  items-center text-xs uppercase  border-4 border-grey-400 bg-blue-400 p-2 text-white shadow-md">
                          Signup
                        </button>
                      </div>

                    </div>
                    {props?.showErrorMessage && (<>
                      <p className='text-red -mt-5' style={{ color: "red" }}>{props?.showErrorMessage}</p>
                    </>)}
                    {/* <div className='flex-col '> <p className='text-white font-thin px-2 text-sm  text-left'>Start your 7-day free trial, no credit card necessary. By providing your email, you agree to our <a className='font-medium'>terms of service.</a></p></div> */}
                  </div>

                }

              </div>

            </div>
          </div>

        </div>

      </div>

    </section>
  );
}

export default Topbanner;