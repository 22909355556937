import React from 'react'

export default function Hostelpayment() {
  return ( 
    <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto  '>
        <div className='flex flex-row justify-between'>
            <div>
            <h1 className='font-bold '>Payment Details</h1>
            </div>
            <div className=''>
                    <button
                        type="submit"
                        className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                        >
                            Save
                    </button>
            </div>
        </div>
     
      <div className="text-center p-8 flex-1">
        <p className='font-bold'>Stripe creator account information?</p>
      </div>

    </div>
  );
}
