import React from 'react'
import Mapdetail from './Mapdetail';
import { Link } from "react-router-dom";

export default function Mapview() {
  return (
     <div className='container mx-auto flex flex-col lg:flex-row  p-1 py-5'>
        <div className='mx-auto border-2 border-gray-100 p-2'>
            <div className='flex flex-row  p-2 container'>
              <p className='text-black'><span className='font-bold'>Community </span>| Hostel Quito | Historical District |</p>
            </div>
            <div class="max-w-xxl w-full lg:flex">
              <div class="h-80 lg:h-auto lg:w-80 flex-none text-center overflow-hidden">
                <Mapdetail />
              </div>
              <div class=" w-full bg-white p-4 flex flex-col justify-between leading-normal">
                <div class="mb-8">
                  <p class="text-sm text-grey-dark flex items-center">
                    <svg class="text-grey w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                    </svg>
                    Members only
                  </p>
                <div className="flex">
                  <div>
                    <div class="text-black  text-xl mb-2">Your <span className='font-bold text-blue-400'>W</span> benefits</div>
                    <p class="text-grey-darker text-sm p-2">
                      <ul>
                        <li>✓ 10% your stay</li>
                        <li>✓ 10% tours</li>
                        <li>✓ 10% breakfast</li>
                        <li>✓ One free beer or cocktail</li>
                        <li>✓ 20% Saturday Bar Crawl</li>
                      </ul>
                    </p>
                  </div>
                  <div className='justify-center p-3 px-10'>
                    <div className='bg-green-400 rounded-full p-1 text-white text-center w-12 h-12'><p className='p-2'>9.4</p></div>
                    <div className='py-3'>
                      <p className='font-bold'>Excellent</p>
                      <p className='w-full'>1390 reviews</p>
                    </div>
                  </div>
                </div>
                </div>
                <div class="flex items-center ">
                  
                  <div className='items-end m-1 px-4'>
                  <button
                      type="submit"
                      className="  border-4 bg-blue-400 py-2 p-3  text-sm font-medium text-white m-1 flex-end"
                    >
                     <Link to="/hosteldetail" >Full Detail</Link>
                    </button>
                    <button
                      type="submit"
                      className="  border-4 bg-blue-400 py-2 p-3  text-sm font-medium text-white m-1 flex-end"
                    >
                      Book Directly 10% off
                    </button>
                  </div>             
                </div>           
              </div>
            </div>
        </div>
    </div>
  );
}
