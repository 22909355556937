import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { API } from '../Backend';
import axios from 'axios';

const PaymentDetails = () => {
  const token = localStorage.getItem('token')
  const { user, isAuth } = useSelector((state) => state.userReducer)
  const [email, setEmail] = useState(user.userId.email)
  const navigate = useNavigate()
  const authAxios = axios.create({
    baseURL: API,
    headers: {
      "Content-type": 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(true);
  // const email = 'chhabraaryan12@gmail.com';

  useEffect(() => {
    if (!isAuth) {
      navigate('/')
    }
  }, [isAuth])

  useEffect(() => {
    if (!localStorage.getItem('clientSecret')) {
      // initiateSubscription();
      fetchConfig();
    }
  }, [])

  const fetchConfig = async () => {
    const res = await authAxios.get(`${API}/api/customer/subscription/config`);
    setStripePromise(loadStripe(res.data.publishableKey));

    localStorage.setItem("clientSecret", res.data.clientSecret);

    setLoading(false);

  }

  const initiateSubscription = async () => {
    const bodyData = {
      email: user.userId.email
    }

    const res = await authAxios.post(`${API}/api/customer/subscription/initiate`, bodyData)

    const { clientSecret, subscriptionId } = res.data;
    let subscriptionData = {
      clientSecret,
      subscriptionId
    }

    localStorage.setItem("subscriptionData", JSON.stringify(subscriptionData));
  }

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <Elements stripe={stripePromise}>

      <PaymentForm email={email} />
    </Elements>
  )
}

const PaymentForm = (props) => {
  const { email } = props;
  const clientSecret = localStorage.getItem('clientSecret');

  // const {subscriptionData} = JSON.parse(localStorage.getItem('subscriptionData'));
  // console.log(subscriptionData);
  const stripe = useStripe();
  const elements = useElements();

  const [paymentDetails, setPaymentDetails] = useState({
    firstName: '',
    lastName: '',
    email: email
  });

  const handleChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const {clientSecret, subscriptionId} = subscriptionData;

    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: paymentDetails.firstName + paymentDetails.lastName
          },
        },
      })
      .then(function (result) {
        // Handle result.error or result.setupIntent
        console.log(result);
      });
    // let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
    //   payment_method: {
    //     card: cardElement,
    //     billing_details: {
    //       name: paymentDetails.firstName + paymentDetails.lastName,
    //     }
    //   }
    // });
    // console.log(error);

    // if(error) {
    //   // show error and collect new card details.
    //   setMessage(error.message);
    //   return;
    // }
    // console.log(paymentIntent.status);
  }

  const handleSkip = () => {

  }

  // Trial Case
  // Skip Case
  // Payment Handle 
  // Backend API

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>First Name</label>
          <input type="text" name="firstName" value={paymentDetails.firstName} onChange={handleChange} />
        </div>

        <div>
          <label>Last Name</label>
          <input type="text" name="lastName" value={paymentDetails.lastName} onChange={handleChange} />
        </div>

        <div>
          <label>Email</label>
          <input disabled type="email" name="email" value={paymentDetails.email} onChange={handleChange} />
        </div>

        <div>
          <CardElement />
        </div>

        <div>
          <button type="submit">Pay Now</button>
        </div>
      </form>
    </div>
  )
}

export default PaymentDetails