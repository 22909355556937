import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const HostelSideBar = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const SIDEBAR_LINKS = [
    {
      label: 'Account',
      path: '/accountinfo'
    },
    {
      label: 'Plan Billing',
      path: '/accountinfo/planbilling'
    },

  ];

  return (
    <div className='container lg:m-12 w-40'>
      <div className='flex flex-col gap-4 respondash'>
        {SIDEBAR_LINKS.map(item =>
          <Link className={classNames(pathName === item.path ? 'text-blue-500' : 'text-neutral-900', 'font-normal text-lg hover:text-blue-500')} to={item.path}>{item.label}</Link>
        )}
      </div>
    </div>
  )
}

export default HostelSideBar;
