import React, { useState, useEffect } from 'react'
import Logo from '../Assets/img/logo.png';
import img1 from '../Assets/img/banner.png'
import { signup } from '../redux/actions/UserAction.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SignUp() {
    const { isAuth, loading } = useSelector((state) => state.userReducer)
    const [details, setDetails] = useState({ email: '', password: '' })
    const [remember, setRemember] = useState(false)
    const [displayError, setDisplayError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();
    const [refCode, setRefCode] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        const ref = searchParams.get('referral')
        console.log(ref)
        if (ref !== null) {
            setRefCode(ref)
        }
        if (isAuth) {
            navigate('/')
        }
    }, [])

    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const { email } = details
        if (!email || regex.test(email) === false) {
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        const userData = { ...details, userType: 'member', refCode: refCode !== null ? refCode : null }
        const { email, password } = details
        if (email.trim().length === 0 || password.trim().length === 0) {
            displayErrorFunction("Please fill all the fields")
        } else if (!emailValidation()) {
            displayErrorFunction("Please enter a valid email")
        } else {
            dispatch(signup(userData))
                .then((res) => {
                    console.log('res', res)
                    if (res.success) {
                        localStorage.setItem('token', res.token)
                        navigate('/checkout')
                    } else {
                        displayErrorFunction(res.message)
                    }
                })
        }
    }

    const displayErrorFunction = (message) => {
        setErrorMessage(message)
        setDisplayError(true)
        setTimeout(() => {
            setDisplayError(false)
            setErrorMessage("")
        }, 3000);
    }

    return (
        <>
            <div>
                <div className="overflow-hidden bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                                <div className="w-full max-w-md space-y-8">
                                    <div>
                                        <img
                                            className="mx-auto h-12 w-auto"
                                            src={Logo}
                                            alt="wanderlust"
                                        />
                                        <h2 className="mt-6 text-center text-3xl font-bold  text-gray-900">
                                            Start your subscription
                                        </h2>
                                        <p className="mt-2 text-center text-bold text-gray-600">
                                            Membership renews monthly, cancel anytime

                                        </p>
                                    </div>
                                    <div className="mt-8 space-y-6" >
                                        <input type="hidden" name="remember" defaultValue="true" />
                                        <div className="-space-y-px rounded-md shadow-sm">
                                            <div>
                                                <label htmlFor="email-address" className="sr-only">
                                                    Email address
                                                </label>
                                                <input
                                                    id="email-address"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    required
                                                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="Email address"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="password" className="sr-only">
                                                    Password
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    required
                                                    className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="Password"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <input
                                                    id="remember-me"
                                                    name="remember-me"
                                                    type="checkbox"
                                                    checked={remember}
                                                    onChange={() => setRemember(!remember)}
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                />
                                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="text-sm">
                                                <a href="/forget" className="font-medium text-indigo-600 hover:text-indigo-500">
                                                    Forgot your password?
                                                </a>
                                            </div>
                                        </div>

                                        <div>
                                            <button
                                                className="group relative flex w-full justify-center rounded-md border-4 py-2 px-4 text-sm font-medium uppercase font-semibold  border-grey bg-blue-400  text-white shadow-md "
                                                onClick={handleSubmit}
                                            >
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3">

                                                </span>
                                                {loading ? 'loading...' : 'Start free trail'}
                                            </button>
                                        </div>
                                    </div>
                                    {!displayError ? <></> : <span className='text-red-600'>{errorMessage}</span>}

                                </div>
                            </div>
                            <img src={img1} alt="" className='w-[48rem] max-w-none rounded-xl   sm:w-[57rem] md:-ml-4 lg:-ml-0' />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
