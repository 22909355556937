import React from "react"

export default function Errorpage() {
    return (
      <div className="container mx-auto">
        <main className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-red-500 sm:text-5xl">Uh oh! I think you’re lost</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">It looks like the page you’re looking for went missing</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="border-4 bg-blue-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 "
            >
              Go back home
            </a>
          </div>
        </div>
      </main>
      </div>
    )
  }
  