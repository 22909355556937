import { PaperClipIcon } from '@heroicons/react/20/solid'

export default function Contactus() {
  return (
    <div className="overflow-hidden mx-auto container">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-2xl font-bold leading-6 text-gray-900">Contact Us</h3>
      </div>
        <div className="px-5 flex flex-row">
         
          <div className='mx-auto'>
              <h1 className='font-bold '>Member Question:</h1>
              <p className='py-2 '>hello@wanderlustmembership.com</p>
          </div>
          <div className='px-96 flex flex-col'>
              <h1 className='font-bold '>Hostel Owners:</h1>
              <p className='py-2'>partner@wanderlustmembership.com</p>
          </div>
       
      </div>
      <div className='py-6 p-5'>
          <h1 className='font-bold text-2xl'>Frequently asked questions</h1>
            <div class="faq-drawer">
            <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer">What’s included in the Wanderlust membership? </label>
            <div class="faq-drawer__content-wrapper">
              <div class="faq-drawer__content">
                <p className='text-base p-2'>
                With the Wanderlust membership you get exclusive deals for hostels, tours, and more. Each hostel has unique offers which might include 10% their nightly rate, a free drink, or 20% of a tour booked with them. Be sure to ask about the current offers when you check-in! 
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
