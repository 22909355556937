import React, { useState } from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"
import { useSelector, useDispatch } from 'react-redux';
import { adminLogin } from '../../redux/actions/HostelAction.js';
import { Navigate ,Link } from 'react-router-dom';

const HostelSignIn = () => {

    const { isAuth, error } = useSelector((state) => state.adminReducer);
    const [formErrors, setFormErrors] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null)
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const dispatch = useDispatch();

    const handleChange = (e) => {
        if (formErrors) {
            setFormErrors({
                ...formErrors,
                [e.target.name]: ""
            })
        }

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let formErrors = validateForm(formData);

        if (Object.keys(formErrors).length !== 0) {
            setFormErrors(formErrors);
        } else {
            dispatch(adminLogin(formData)).then((res) => {
                if (!res.success) {
                    setErrorMsg(res.message)
                }
            });
        }
    }

    const validateForm = (values) => {
        let errors = {};
        let { email, password } = values;

        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email) {
            errors.email = "Please enter your email address";
        } else if (!emailRegex.test(email)) {
            errors.email = "Please enter valid email address";
        }

        if (!password) {
            errors.password = "Please enter your password";
        }

        return errors;
    }

    if (isAuth) {
        return <Navigate to="/hostel/dashboard" />
    }

    return (
        <div>
            <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    <div className="hidden bg-cover hotel-bg lg:block lg:w-1/3" >
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50 ">
                            <div>
                                <h2 className="text-3xl font-bold text-white text-center p-5">New to Wanderlust?</h2>
                                <h2 className="text-2xl font-medium text-white text-center p-5">Become a trusted parter to list your hostel or tours</h2>

                                <ul className="text-white font-medium p-5">
                                    <li>More direct bookings</li>
                                    <li>Higher profit margin</li>
                                    <li>List your hostel or tour without 3rd party fees</li>
                                    <li>By hostel owners for hostel owners</li>
                                </ul>
                                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-300 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"><Link to="/hostel/signup">Sign up</Link></button>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-4/6">
                        <div className="flex-1">
                            <div className="text-center">
                                <div className='flex justify-center'>
                                    <img src={Logo} width='100' alt="" />
                                </div>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Sign in to access your account</p>
                            </div>

                            <div className="mt-8">
                                <form onSubmit={handleSubmit} noValidate>
                                    <div>
                                        <label for="email" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email Address</label>
                                        <input type="email" name="email" id="email" value={formData.email} onChange={handleChange} placeholder="example@example.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formErrors && formErrors.email && <p className="my-2 text-red-600">{formErrors.email}</p>}
                                    </div>

                                    <div className="mt-6">
                                        <div className="flex justify-between mb-2">
                                            <label for="password" className="text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        </div>

                                        <input type="password" name="password" id="password" value={formData.password} onChange={handleChange} placeholder="Your Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formErrors && formErrors.password && <p className="my-2 text-red-600">{formErrors.password}</p>}

                                    </div>

                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Sign in
                                        </button>
                                        <p className='text-red-600'>{errorMsg !== null && errorMsg}</p>
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline"><Link to='/hostel/forgetpassword'>Forgot Password?</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostelSignIn