import {
    ADMIN_ACTIVATE_FAIL,
    ADMIN_ACTIVATE_REQUEST,
    ADMIN_ACTIVATE_SUCCESS,
    ADMIN_SIGNIN_FAIL,
    ADMIN_SIGNIN_REQUEST,
    ADMIN_SIGNIN_SUCCESS,
    ADMIN_SIGNUP_FAIL,
    ADMIN_SIGNUP_REQUEST,
    ADMIN_SIGNUP_SUCCESS,
    ADMIN_SIGNOUT,
    ADMIN_UPDATE_FAIL,
    ADMIN_UPDATE_REQUEST,
    ADMIN_UPDATE_SUCCESS,
    HOSTEL_IMAGE_FAIL,
    HOSTEL_IMAGE_REQUEST,
    HOSTEL_IMAGE_SUCCESS,
} from '../constants/hostelConstant.js'
import { API } from '../../Backend'
import axios from 'axios'


export const adminLogin = (hostelAdmin) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_SIGNIN_REQUEST })

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post('/api/admin/signin', hostelAdmin);
        localStorage.setItem('hostelInfo', JSON.stringify(data.hostelInfo));
        dispatch({ type: ADMIN_SIGNIN_SUCCESS, payload: data.hostelInfo });
    } catch (err) {
        dispatch({ type: ADMIN_SIGNIN_FAIL, payload: err.response.data.message })
        return err.response.data
    }
}

export const adminLogout = () => (dispatch) => {
    localStorage.removeItem('hostelInfo');
    dispatch({ type: ADMIN_SIGNOUT })
}

export const adminSignUp = (hostelAdmin) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_SIGNUP_REQUEST })

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.post('/api/admin/signup', hostelAdmin);

        dispatch({ type: ADMIN_SIGNUP_SUCCESS, payload: data.hostelDetails });
        return data
    } catch (err) {
        dispatch({ type: ADMIN_SIGNUP_FAIL, payload: err.response.data.message });
        return err.response.data
    }
}

export const adminactivate = (user) => async (dispatch) => {
    try {
        const { ownerId, token } = user
        dispatch({ type: ADMIN_ACTIVATE_REQUEST })


        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
            }
        });

        const { data } = await authAxios.put(`/api/admin/approved/${ownerId}/${token}`, user)

        dispatch({ type: ADMIN_ACTIVATE_SUCCESS, payload: data })
        return data
    } catch (err) {
        dispatch({ type: ADMIN_ACTIVATE_FAIL, payload: err.response.data.message })
        return err.response.data
    }
}

export const adminupdate = (user) => async (dispatch) => {
    try {
        console.log('user', user)
        dispatch({ type: ADMIN_UPDATE_REQUEST })
        const token = JSON.parse(localStorage.getItem('hostelInfo')).token

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`
            }
        });

        const { data } = await authAxios.patch('/api/admin/accountupdate', user);
        const data1 = {
            hostelDetails: data.owner,
            token
        }
        localStorage.setItem('hostelInfo', JSON.stringify(data1));


        dispatch({ type: ADMIN_UPDATE_SUCCESS, payload: { owner: data1, message: data.message } });

        return data
    } catch (err) {
        dispatch({ type: ADMIN_UPDATE_FAIL, payload: err.response.data.message })
        return err.response.data
    }
}


export const hostelimage = (user) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_UPDATE_REQUEST })
        const token = JSON.parse(localStorage.getItem('hostelInfo')).token

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'multipart/form-data',
                Authorization: `Bearer ${token}`

            }
        });

        const { data } = await authAxios.patch('/api/admin/uploadimage', user);
        const data1 = {
            hostelDetails: data.owner,
            token
        }
        localStorage.setItem('hostelInfo', JSON.stringify(data1));
        dispatch({ type: ADMIN_UPDATE_SUCCESS, payload: { owner: data1, message: data.message } });
        return data;
    } catch (err) {
        dispatch({ type: ADMIN_UPDATE_FAIL, payload: err.response.data.message });
        return err.response.data
    }
}


export const deleteimage = (user) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_UPDATE_REQUEST })
        const token = JSON.parse(localStorage.getItem('hostelInfo')).token

        const authAxios = axios.create({
            baseURL: API,
            headers: {
                "Content-type": 'application/json',
                Authorization: `Bearer ${token}`

            }
        });

        const { data } = await authAxios.patch('/api/admin/deleteimage', user);
        const data1 = {
            hostelDetails: data.owner,
            token
        }
        localStorage.setItem('hostelInfo', JSON.stringify(data1));
        dispatch({ type: ADMIN_UPDATE_SUCCESS, payload: { owner: data1, message: data.message } });
        return data;
    } catch (err) {
        dispatch({ type: ADMIN_UPDATE_FAIL, payload: err.response.data.message });
        return err.response.data
    }
}