import React, { useEffect, useState } from 'react'
import hostelimg from "../Assets/img/banner.png"
import Review from './Review'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Hosteldetail() {
  const { id } = useParams()
  const { hostel } = useSelector((state) => state.hostelReducer)
  const [data, setData] = useState({})
  const [currentImage, setCurrentImage] = useState(0);

  const previousImage = () => {
    setCurrentImage((currentImage - 1 + data.image.length) % data.image.length);
  };

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % data.image.length);
  };


  useEffect(() => {
    const item = hostel.filter((i) => i._id === id);
    console.log('item', item)
    setData(item[0])
  }, [])

  return (
    <div className='container mx-auto py-10'>
      <div className='border-2 border-gray-100 mx-auto'>

        <div className="max-w-xxl  py-2 px-2 w-full lg:flex">
          <div className="h-90 lg:h-auto lg:w-2/5 flex-none text-center overflow-hidden" title="">
            <div>
              <div className="relative w-full">
                <div className="overflow-hidden rounded-md">
                  <img src={Object.keys(data).length !== 0 && data.image.length !== 0 ? data.image[currentImage] : "https://cdn.pixabay.com/photo/2021/12/11/07/59/hotel-6862159__340.jpg"} alt="carousel" className="w-full h-80 object-fill" />
                </div>
                <div className="absolute inset-y-0 left-0 flex items-center justify-center w-10 h-full text-gray-800  bg-opacity-50 hover:bg-opacity-75 transition duration-300 ease-in-out">
                  <button onClick={previousImage} className="focus:outline-none">
                    <span className='text-gray-100 text-2xl' >❮</span>
                  </button>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center justify-center w-10 h-full text-gray-800 bg-opacity-50 hover:bg-opacity-75 transition duration-300 ease-in-out">
                  <button onClick={nextImage} className="focus:outline-none ">
                    <span className='text-gray-100 text-2xl' >❯</span>
                  </button>
                </div>
              </div>
              {/* <div className="carousel w-full">
                <div id="slide1" className="carousel-item relative w-full">
                  <img src={Object.keys(data).length !== 0 && data.image.length !== 0 ? data.image[0] : "https://cdn.pixabay.com/photo/2021/12/11/07/59/hotel-6862159__340.jpg"} className="w-full h-80 object-fill" />
                  <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                    <a href="#slide4" className="btn btn-circle bg-white rounded-full p-1">❮</a>
                    <a href="#slide2" className="btn btn-circle bg-white rounded-full p-1">❯</a>
                  </div>
                </div> */}



              {/* <div id="slide2" className="carousel-item relative w-full">
                        <img src="" className="w-full" />
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide1" className="btn btn-circle">❮</a> 
                        <a href="#slide3" className="btn btn-circle">❯</a>
                        </div>
                    </div> 
                    <div id="slide3" className="carousel-item relative w-full">
                        <img src="" className="w-full" />
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide2" className="btn btn-circle">❮</a> 
                        <a href="#slide4" className="btn btn-circle">❯</a>
                        </div>
                    </div> 
                    <div id="slide4" className="carousel-item relative w-full">
                        <img src="" className="w-full" />
                        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide3" className="btn btn-circle">❮</a> 
                        <a href="#slide1" className="btn btn-circle">❯</a>
                        </div>
                    </div> */}
              {/* </div> */}
              <div className='p-1'>
                <Review reviews={data?.reviews} />
              </div>
            </div>

          </div>
          {
            Object.keys(data).length !== 0 &&

            <div className=" w-full bg-white p-4 px-9 flex flex-col justify-between leading-normal">
              <div class="mb-8 flex flex-col">
                <div className='flex flex-row'>
                  <div className='flex flex-col w-50 '>
                    <p className='text-black'><span className='font-bold text-4xl p-'>{data.hostelName}</span></p>
                    <p>{data.hostelAddress} </p>
                  </div>
                  {/* <div className='justify-center p-3 px-8 flex '>
                        <div className='bg-green-400 rounded-full p-1 text-white text-center w-12 h-12'><p className='p-2'>9.4</p></div>
                        <div className='py-1 flex flex-col text-center px-4'>
                            <div><p className='font-bold'>Excellent</p></div>
                            <div><p className='w-full'>1390 reviews</p></div>
                        </div>
                    </div> */}
                </div>

                <div className="">
                  <h1 className='font-bold text-xl'>Property Description</h1>
                  <p className='text-basw'>
                    {data.description}
                  </p>
                </div>
              </div>
              <div className="flex items-center ">
                <div className="text-sm">
                  <p className="text-black text-center leading-none">Starting At</p>
                  <p className="text-grey-dark "><span className='font-bold text-center text-2xl '>{data.startPrice}</span>/ Per Night</p>
                </div>
                <div className='items-end m-1 px-4'>
                  <a href={data.bookingOption === 'website' ? `${data.website}` : `https://wa.me/${data.hostelPhone}`} target="_blank">
                    <button
                      type="submit"
                      className="  border-4 bg-blue-400 py-2 p-3  text-sm font-medium text-white m-1 flex-end"
                    >
                      Book Directly 10% off
                    </button>
                  </a>

                </div>
              </div>
              <div className='py-4'>
                <hr />
                <div className="text-black  text-xl mb-2 p-2">Your <span className='font-bold text-blue-400'>W</span> benefits</div>
                <p className="text-grey-darker text-sm p-2">
                  <ul>
                    {
                      data.offers.map((off, index) => (
                        <React.Fragment key={index}>
                          <li>{off}</li>
                        </React.Fragment>
                      ))
                    }
                  </ul>
                </p>
              </div>
            </div>
          }
        </div>

      </div>

    </div >
  )
}
