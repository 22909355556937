import React, { useState } from 'react'
import Logo from "../../Assets/img/logo/W light transparent.png"
import { useSelector, useDispatch } from 'react-redux';
import { adminLogin } from '../../redux/actions/HostelAction.js';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios'
import { API } from '../../Backend';

const HostelResetPassword = () => {
    const { ownerId, token } = useParams()
    const { isAuth } = useSelector((state) => state.adminReducer);
    const [formErrors, setFormErrors] = useState(null);
    const [formData, setFormData] = useState({
        password: '',
        confirmpassword: ''
    })
    const [success, setSuccess] = useState(null)
    const dispatch = useDispatch();

    const handleChange = (e) => {
        if (formErrors) {
            setFormErrors({
                ...formErrors,
                [e.target.name]: ""
            })
        }

        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formErrors = validateForm(formData);

        if (Object.keys(formErrors).length !== 0) {
            setFormErrors(formErrors);
        } else if (formData.password !== formData.confirmpassword) {
            let errors = {}
            errors.error = 'Password not matched with confirm password'
            setFormErrors(errors)
        } else {
            const authAxios = axios.create({
                baseURL: API,
                headers: {
                    "Content-type": 'application/json',
                }
            });
            console.log(formData)
            const { data } = await authAxios.put(`/api/admin/resetpassword/${ownerId}/${token}`, formData);
            console.log(data)
            if (data.success) {
                setSuccess(data.message)
                setFormData({ password: '', confirmpassword: '' })
            }
        }

    }

    const validateForm = (values) => {
        let errors = {};
        let { password, confirmpassword } = values;

        if (!password) {
            errors.password = "Please enter your Password";
        } else if (!confirmpassword) {
            errors.confirmpassword = "Please enter Confirm password";
        }

        return errors;
    }

    if (isAuth) {
        return <Navigate to="/hostel/dashboard" />
    }

    return (
        <div>
            <div className="bg-white dark:bg-gray-900">
                <div className="flex justify-center h-screen">
                    <div className="hidden bg-cover hotel-bg lg:block lg:w-1/3" >
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50 ">
                            <div>
                                <h2 className="text-3xl font-bold text-white text-center p-5">New to Wanderlust?</h2>
                                <h2 className="text-2xl font-medium text-white text-center p-5">Become a trusted parter to list your hostel or tours</h2>

                                <ul className="text-white font-medium p-5">
                                    <li>More direct bookings</li>
                                    <li>Higher profit margin</li>
                                    <li>List your hostel or tour without 3rd party fees</li>
                                    <li>By hostel owners for hostel owners</li>
                                </ul>
                                <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-300 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">Sign up</button>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-4/6">
                        <div className="flex-1">
                            <div className="text-center">
                                <div className='flex justify-center'>
                                    <img src={Logo} width='100' alt="" />
                                </div>

                                <p className="mt-3 text-gray-500 dark:text-gray-300">Reset Your Password</p>
                            </div>

                            <div className="mt-8">
                                <form onSubmit={handleSubmit} noValidate>
                                    <div>
                                        <label for="password" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Password</label>
                                        <input type="password" name="password" id="passwordd" value={formData.password} onChange={handleChange} placeholder="password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formErrors && formErrors.password && <p className="my-2 text-red-600">{formErrors.password}</p>}
                                    </div>

                                    <div>
                                        <label for="confirmpassword" className="block mb-2 text-sm text-gray-600 dark:text-gray-200">ConfirmPassword</label>
                                        <input type="password" name="confirmpassword" id="email" value={formData.confirmpassword} onChange={handleChange} placeholder="confirmpassword" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                        {formErrors && formErrors.confirmpassword && <p className="my-2 text-red-600">{formErrors.confirmpassword}</p>}
                                    </div>
                                    <div className="mt-6">
                                        <button
                                            type="submit"
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-400 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                            Reset Your Password
                                        </button>
                                        {formErrors && formErrors.error && <p className="my-2 text-red-600">{formErrors.error}</p>}
                                        {success !== null && success}
                                    </div>

                                </form>

                                <p className="mt-6 text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Forgot Password?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostelResetPassword