import React, {useState} from 'react'
import {useSearchParams, useNavigate} from 'react-router-dom';
import PageDetails from '../../component/hostelOnboard/PageDetails';
import SetPassword from '../../component/hostelOnboard/SetPassword';
import { API } from '../../Backend'
import axios from 'axios'

const HostelApproval = () => {

    const authAxios = axios.create({
        baseURL: API,
        headers: {
            "Content-type": 'application/json',
        }
    });

    let [searchParams, setSearchParams] = useSearchParams()
    const ownerId = searchParams.get("ownerId")
    const token = searchParams.get("token");

    const navigate = useNavigate();
    
    const [step, setStep] = useState(1);
    // console.log(ownerId, token);
    const [details, setDetails] = useState({
        password: '',
        startPrice: '',
        description: ''
    })

    const addStep = () => {
        setStep(step+1);
    }

    const handleChange = (e) => {
        setDetails({
            ...details,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(details);
        const { data } = await authAxios.post(`/api/admin/approved/${ownerId}/${token}`, details)
        if(data.success) {
            navigate('/hostel/login');
        }
    }
  
  return (
      <div className="bg-white dark:bg-gray-900 flex justify-center h-screen">
          <div className="hidden bg-cover hotel-bg lg:block lg:w-1/3" >
              <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-50 ">
                  <div>
                      <h2 className="text-2xl font-medium text-white text-center p-5">By Hostel Owners For Hostel Owners</h2>
                      
                      <ul className="text-white font-medium p-5">
                          <li>More direct bookings</li>
                          <li>Higher profit margin</li>
                          <li>List your hostel or tour without 3rd party fees</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-4/6">
            {step === 1 && <SetPassword addStep={addStep} details={details} handleChange={handleChange}/>}
            {step === 2 && <PageDetails details={details} handleChange={handleChange} handleSubmit={handleSubmit}/>}

          </div>
      </div>
  );
}

export default HostelApproval