import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios';
import { API } from '../../../Backend.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Addmember() {
    const { hostelDetails } = useSelector((state) => state.adminReducer.hostelDetails);
    const [memberEmail, setMemberEmail] = useState('');
    const [refCode, setRefCode] = useState('')

    useEffect(() => {
        if (hostelDetails !== undefined) {
            setRefCode(hostelDetails.referralId.refCode)
        }
    }, [hostelDetails])

    const handleSubmit = async () => {
        try {
            const token = JSON.parse(localStorage.getItem('hostelInfo')).token;

            const authAxios = axios.create({
                baseURL: API,
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            const { data } = await authAxios.post('/api/admin/referral', { memberEmail });
            if (data.success) {
                setMemberEmail('')
                toast.success("Referral Invite send successfully")
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }

    }
    return (
        <div className='container m-12 py-3 bg-white p-4 w-2/3 mx-auto  '>
            <div className='flex flex-row justify-between'>
                <div>
                    <h1 className='font-bold '>Add Member</h1>
                    <p className='text-sm font-light sm:text-left py-3 '>For every new member that uses your referral code when signing up, you’ll receive $5. Your referral code will be included in the email when you invite a new member below.</p>
                </div>
                <div className=''>
                    <button
                        type="submit"
                        className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                    >
                        Save
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-2 respon py-5">
                <div><div className="grid grid-cols-2 respon">
                    <div className="flex flex-row py-2 gap-20 respon">
                        <span className="w-1/2">
                            <label for="country" className="block text-xs w-full font-semibold text-gray-600 uppercase">Firstname</label>
                            <input
                                type="text"
                                name="Firstname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

                            />

                        </span>
                        <span className="w-1/2 respon">
                            <label for="zipCode" className="block text-xs font-semibold text-gray-600 uppercase">Last Name</label>
                            <input
                                type="text"
                                name="Lastname"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

                            />

                        </span>
                    </div>
                </div>
                    <div className='w-full respon'>
                        <span className="respon">
                            <label for="Email" className="block text-xs font-semibold text-gray-600 uppercase">Email</label>
                            <input
                                type="email"
                                name="Email"
                                onChange={(e) => setMemberEmail(e.target.value)}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md"

                            />

                        </span>
                        <div className='py-5 text-center respon'>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className=" bg-blue-400 px-3 py-1 text-sm font-sm text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2  "
                            >
                                Send Invite
                            </button>
                        </div>
                    </div>

                </div>


                <div>
                    <span className="">
                        <label for="Email" className="block text-xs font-semibold text-gray-600 uppercase">Your Referral Code</label>
                        <input
                            type="text"
                            name="Email"
                            disabled
                            value={refCode}
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400  sm:text-sm sm:leading-6 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset  sm:max-w-md" placeholder='Community Hostel'

                        />

                    </span>
                    <div className='p-2'><p className='text-sm'>Make sure to have new members input your referral code when first signing up to receive referral bonus.</p></div>
                </div>
            </div>
            <div>
                <ToastContainer />

            </div>

        </div>
    )
}

