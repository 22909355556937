import { combineReducers } from "redux"
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist';
import { userReducer, forgotReducer, activateReducer } from "./UserReducer";
import { hostelReducer, adminReducer } from "./HostelReducer";
import { RESET } from "../constants/UserConstant";

const persistConfig = {
    key: 'root',
    storage
}

const authPersistConfig = {
    key: 'userReducer',
    storage,
    blacklist: ['loading']
}

const hostel = {
    key: 'hostelReducer',
    storage,
    blacklist: ['loading']
}

const appReducer = combineReducers(
    {
        userReducer: persistReducer(authPersistConfig, userReducer),
        forgotReducer,
        activateReducer,
        hostelReducer: persistReducer(hostel, hostelReducer),
        adminReducer
    }
)

const rootReducer = (state, action) => {
    if (action.type === RESET) {
        // reset state
        state = undefined;
        // reset local storage
        localStorage.clear();
    }
    return appReducer(state, action)
}

export default rootReducer
// export default persistReducer(persistConfig, rootReducer); 