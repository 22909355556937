import React, { useEffect, useState } from 'react'
import axios from 'axios';
import summaryimg from "../Assets/img/summary.PNG";
import { API } from '../Backend.js'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CheckoutPage = () => {
    const [data, setData] = useState('')
    const { user, isAuth } = useSelector((state) => state.userReducer)
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const [formData, setFormData] = useState([])


    useEffect(() => {
        setEmail(user.userId.email)
    }, [user])

    const setFormInputs = (e) => {
        let val = e.target.value
        setFormData({ ...formData, [e.target.name]: val })
    }
    console.log(user)
    const getUrl = async () => {
        try {
            const token = localStorage.getItem('token')
            const authAxios = axios.create({
                baseURL: API,
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            const { data } = await authAxios.post(`/api/subscription/checkout`, formData);
            setData(data.redirectUrl)
            return data.redirectUrl
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
    }

    const handleSubmit = async () => {
        const url = await getUrl();

        if (url !== undefined) {
            window.open(url, '_blank')
        }
    }
    return (
        <div className="container mx-auto py-24 grid grid-cols-2 pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48 flex-1 flex-col">

            <div className="mt-8 sm:mt-0">
                <div className="p-1">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0 p-4">
                            <h3 className="text-base font-semibold leading-6 text-gray-900 ">Contact Information</h3>

                        </div>
                    </div>
                    <div className="mt-4 md:col-span-2 md:mt-0">
                        <div>
                            <div className="overflow-hidden shadow sm:rounded-md">
                                <div className="bg-white px-4 py-5 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-4">
                                            <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <input
                                                type="text"
                                                name="email-address"
                                                id="email-address"
                                                autoComplete="email"
                                                value={email}
                                                disabled
                                                className="mt-1 block w-full rounded-md border-gray-100  focus:border-gray-500 border-2 sm:text-lg"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                First name
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="first-name"
                                                autoComplete="given-name"
                                                onChange={setFormInputs}
                                                className="mt-1 block w-full rounded-md border-gray-100  focus:border-gray-500 border-2 sm:text-lg"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                Last name
                                            </label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                id="last-name"
                                                autoComplete="family-name"
                                                onChange={setFormInputs}
                                                className="mt-1 block w-full rounded-md border-gray-100  focus:border-gray-500 border-2 sm:text-lg"
                                            />
                                        </div>
                                        {( !user?.referralId || user?.referralId === null) && <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                                Referal Id
                                            </label>
                                            <input
                                                type="text"
                                                name="referralId"
                                                id="referralId"
                                                onChange={setFormInputs}
                                                className="mt-1 block w-full rounded-md border-gray-100  focus:border-gray-500 border-2 sm:text-lg"
                                            />
                                        </div>}
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center border-4  bg-blue-400 py-2 px-6 text-sm font-medium text-white shadow-sm"
                                        onClick={handleSubmit}
                                    >
                                        Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container col-sm-6 bg-green-300 rounded-md p-3 py-1 flex flex-col">
                <div className="summary ">
                    <h1 className="p-2 font-bold">Summary</h1>
                    <div className="flex justify-center p-1 text-center">
                        <div className="px-10"><img src={summaryimg} alt="" /></div>
                        <div className="text-center py-10"><p className="font-bold ">Wanderlust Membership - $10.00/ month</p></div>
                    </div>
                </div>
                <div className="text-center mx-auto py-6">
                    <ul className="font-bold p-1">
                        <li>
                            subtotal : $10.00
                        </li>
                        <li>
                            Taxes : $1.00
                        </li>
                        <li>
                            Total : $11.00
                        </li>
                    </ul>
                </div>
                <div className="text-center mx-auto py-10">
                    <h2> Cancel anytime :)</h2>
                    <p>Need help?<span className="font-bold">

                        <a href='mailto:hello@wanderlustcard.com?subject=Need help&body=Hello!'>
                            Contact us
                        </a>
                    </span></p>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default CheckoutPage