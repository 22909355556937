import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { API } from '../Backend';
const WanderlustOwner = () => {
    const { key } = useParams();
    const [hostels, setHostels] = useState([]);
    const [error, setError] = useState(null)

    useEffect(() => {

        const getdata = async () => {
            try {
                const authAxios = axios.create({
                    baseURL: API,
                    headers: {
                        "Content-type": 'application/json',
                        Authorization: `Bearer ${key}`
                    }
                });

                const { data } = await authAxios.get('/api/admin/wanderlust/data');
                setHostels(data.hostels)
            } catch (err) {
                setError(err.response.data.message)
                setHostels([])
            }

        }

        getdata()
    }, [])

    const handleApprove = async (e, hostel) => {
        try {
            const authAxios = axios.create({
                baseURL: API,
                headers: {
                    "Content-type": 'application/json',
                    Authorization: `Bearer ${key}`
                }
            });

            const { data } = await authAxios.post(`/api/admin/${hostel.ownerId._id}`);
            console.log('data', data);
            const hos = hostels.filter((i) => i._id !== hostel._id)
            setHostels(hos)
        } catch (err) {

        }
        console.log('histel', hostel)
    }

    if (error) {
        return (
            <div className='text-red-600'>
                {error}
            </div>
        )
    }
    return (

        <div className="overflow-x-auto sm:rounded-lg py-20">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Email
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Hostel Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Hostel Phone
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Website
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hostels.map((h, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {h.ownerId.email}
                            </th>
                            <td className="px-6 py-4">
                                {h.hostelName}
                            </td>
                            <td className="px-6 py-4">
                                {h.hostelPhone}
                            </td>
                            <td className="px-6 py-4">
                                {h.website}
                            </td>
                            <td className="px-6 py-4 text-right">
                                <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                    <button onClick={(e) => handleApprove(e, h)}>
                                        Approve
                                    </button>
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    )
}

export default WanderlustOwner