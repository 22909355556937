


export default function AboutUs() {
    return (
      <>
          <div className="relative overflow-hidden  bg-white p-4">
            <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
              <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
                <div className="sm:max-w-lg">
                  <h1 className="font text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  We’re on a mission
                  </h1>
                  <p className="mt-4 text-medium text-gray-500">
                  <span className='font-bold text-black'>To support travelers</span> with great deals so they can focus on what travel is all about: connection.When traveling on a budget, we know a couple bucks here and there can actually make a huge difference, so withWanderlust do a bit more: have that beer on the rooftop,dance the night away at the pub crawl, and stay an extra night to connect more with the people around you.<span className='font-bold text-black'>To empower hostels, tour companies, and the local tourist economy in South America</span> with more direct bookings and custom experience offers for their guests.We love the way travel is a win-win for both the guest and the local; it provides jobs, introspection, laughter, and lots of learning.
                  </p>
                </div>
                <div className="sm:max-w-lg py-6">
                  <h1 className="font text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Locally Focused
                  </h1>
                  <p className="mt-4 text-meduim text-gray-500">
                  We <span className='font-bold text-black'> give back 10% of our profits to local charities</span> like Amazoonico, Asociación Caimito Sustentable, and the COAGRO Foundation.
                  </p>
                </div>
                <div>
                  <div className="mt-10 p-3 py-1 mx-1 overflow-hidden">
                    {/* Decorative image grid */}
                    <div
                      aria-hidden="true"
                      className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                    >
                      <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                        <div className="flex items-center space-x-6 lg:space-x-8">
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                              <img
                                src="https://images.pexels.com/photos/5599599/pexels-photo-5599599.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/7968279/pexels-photo-7968279.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/164595/pexels-photo-164595.jpeg?auto=compress&cs=tinysrgb&w=300"
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/189296/pexels-photo-189296.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/70441/pexels-photo-70441.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/2096983/pexels-photo-2096983.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="h-64 w-44 overflow-hidden rounded-lg">
                              <img
                                src="https://images.pexels.com/photos/279746/pexels-photo-279746.jpeg?auto=compress&cs=tinysrgb&w=300"
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </>
      
    )
  }
  