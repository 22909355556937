export const ADMIN_SIGNIN_SUCCESS = 'ADMIN_SIGNIN_SUCCESS'
export const ADMIN_SIGNIN_FAIL = 'ADMIN_SIGNIN_FAIL'
export const ADMIN_SIGNIN_REQUEST = 'ADMIN_SIGNIN_REQUEST'

export const ADMIN_SIGNUP_SUCCESS = 'ADMIN_SIGNUP_SUCCESS'
export const ADMIN_SIGNUP_FAIL = 'ADMIN_SIGNUP_FAIL'
export const ADMIN_SIGNUP_REQUEST = 'ADMIN_SIGNUP_REQUEST'

export const ADMIN_ACTIVATE_SUCCESS = 'ADMIN_ACTIVATE_SUCCESS'
export const ADMIN_ACTIVATE_FAIL = 'ADMIN_ACTIVATE_FAIL'
export const ADMIN_ACTIVATE_REQUEST = 'ADMIN_ACTIVATE_REQUEST'

export const ADMIN_UPDATE_SUCCESS = 'ADMIN_UPDATE_SUCCESS'
export const ADMIN_UPDATE_REQUEST = 'ADMIN_UPDATE_REQUEST'
export const ADMIN_UPDATE_FAIL = 'ADMIN_UPDATE_FAIL'

export const HOSTEL_IMAGE_SUCCESS = 'HOSTEL_IMAGE_SUCCESS'
export const HOSTEL_IMAGE_FAIL = 'HOSTEL_IMAGE_FAIL'
export const HOSTEL_IMAGE_REQUEST = 'HOSTEL_IMAGE_REQUEST'

export const ADMIN_SIGNOUT = 'ADMIN_SIGNOUT';

