import React, { useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export default function Home(props) {
  useEffect(() => {
    console.log(props, "props")
  }, [])


  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyDEfHaJaNnVo7Ie1YGLH1a-cN_7prul47c',
  // });



  // if (!isLoaded) return <div>Loading...</div>;
  return <Map prop={props} />;
}

function Map(props) {

  const center = useMemo(() => ({ lat: Number(props?.prop?.lat), lng: Number(props?.prop?.long) }), []);
  console.log(center, "lat ")
  return (
    <GoogleMap zoom={16} center={center} mapContainerClassName="map-container">
      <Marker position={center} />
    </GoogleMap>
  );
}