import React, { useState, useEffect } from 'react';
import Customerreview from './Customerreview';
import Mapdetail from "./Mapdetail";
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Review({ reviews }) {
  const [activeTab, setActiveTab] = useState("tab1");
  const handleTab1 = () => { setActiveTab("tab1") };
  const handleTab2 = () => { setActiveTab("tab2") };
  const { id } = useParams()
  const { hostel } = useSelector((state) => state.hostelReducer)
  const [data, setData] = useState({})

  useEffect(() => {
    const item = hostel.filter((i) => i._id === id);
    setData(item[0])
  }, [])

  return (
    <div >
      <div>
        <ul className="nav">
          <li className={activeTab === "tab1" ? "active" : ""} onClick={handleTab1}>Customer review</li>
          <li className={activeTab === "tab2" ? "active" : ""} onClick={handleTab2}>Map</li>
        </ul>
      </div>
      <div>
        <div className="outlet">
          {activeTab === "tab1" ?
            (<>
              <div className='w-full'>
                {reviews?.map((review) => (<>
                  <Customerreview review={review} />
                </>))}
              </div>
            </>)
            : (Object.keys(data).length !== 0 && <Mapdetail lat={data.lat} long={data.long} />)}
        </div>

      </div>
    </div>
  );
}
