import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Topbanner from '../component/Topbanner'
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { activateAccount, signup } from '../redux/actions/UserAction.js'
import hostelimg from "../Assets/img/banner.png"
import { HiMapPin } from "react-icons/hi2";
import { Link } from 'react-router-dom'
import { gethostel } from '../redux/actions/UserAction.js'


function Homepage() {
  const list = [' 10% off + stays', ' 10% off + day tours', ' Exclusive hostel offers : free drinks, discounted meals, activities', '  Exclusive community connection'];
  const listItems = list.map((list) => <li><span className='p-1'>✓</span>
    {list} </li>);

  const { hostel } = useSelector((state) => state.hostelReducer)
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null)
  const [selectedHostels, setSelectedHostels] = useState([]);

  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedHostels(hostel)
    let locs = [{ name: 'All' }];
    let locations = hostel.reduce(function (acc, curr) {
      if (!acc[curr.city]) {
        acc[curr.city] = curr.country;
        locs.push({ id: curr, name: curr.city });
      }
      return acc;
    }, {})

    setLocations(locs);
    setSelected(locs[0]);
    setLoading(false);

  }, [hostel])

  useEffect(() => {
    dispatch(gethostel())
  }, [])


  useEffect(() => {
    if (hostel !== null && selected !== null) {
      if (selected.name === 'All') {
        setSelectedHostels(hostel);
      } else {
        let hostels = hostel.filter(h => h.city === selected.name);
        setSelectedHostels(hostels);
      }
    }
  }, [selected])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const handleSignup = (email) => {
    const user = { email }
    dispatch(signup(user))
      .then((res) => {
        console.log('signuip', res)
        if (res.success) {
          setShowMessage(true)
          setShowErrorMessage(false)
        } else {
          setShowErrorMessage(res?.message)
        }
      })
  }

  const handleOpen = (e, data) => {
    // window.open(url, '_blank');
    e.preventDefault()
    console.log(data)
    const url = data.bookingOption === 'website' ? `${data.website}` : `https://wa.me/${data.hostelPhone}`
    console.log(url, data)
    window.open(url, "_blank")
  }

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div>
      <Topbanner
        List={listItems}
        onSignup={handleSignup}
        showMessage={showMessage}
        showErrorMessage={showErrorMessage}
        setShowErrorMessage={showErrorMessage}
      />
      {/* Map search box */}

      <div className='container mx-auto flex  searchbar w-50 '>
        <div className='w-80 '>
          <Listbox value={selected} onChange={setSelected} >
            {({ open }) => (
              <>
                <div className=" ">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="flex items-center ">
                      <span className=" flex ml-3 block truncate"><span className='p-1'><HiMapPin /></span>
                        {selected.name}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">

                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                      {/* // people.map((person) => ( */}
                      {hostel !== null && locations.map((person) => (
                        <Listbox.Option
                          key={person._id}
                          className={({ active }) =>
                            classNames(
                              active ? 'text-white bg-indigo-600' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={person}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">

                                <span
                                  className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                >
                                  {person.name}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

      </div>



      {/* <div className='container sm:container-none mx-auto py-6 flex justify-end'>
        <button type="submit" className=" mapbtn border-4 py-2 p-4 text-sm font-medium text-gray-500" >Map</button>
      </div> */}

      <div className='container mx-auto cardrespon p-2 grid grid-cols-3 '>
        {/* card 1 */}
        {selectedHostels !== null && selectedHostels.map((data, index) => (
          <Link to={`/hosteldetail/${data._id}`}>
            <div className='px-1 mx-2 border-2 border-gray-100 my-2' key={index}>

              <div className='flex flex-row w-50 p-2'>
                <p className='text-black'><span className='font-bold'>{data.hostelName}
                  <br /></span>{data.hostelAddress}</p>
              </div>
              <div class="max-w-xxl w-full lg:flex">

                <div class="h-auto lg:h-auto lg:w-48 flex-none text-center overflow-hidden" title="">
                  <img src={data.image.length !== 0 ? data.image[0] : hostelimg} alt="" />
                </div>
                <div class=" w-full border-grey-light bg-white p-4 flex flex-col justify-between leading-normal">
                  <div class="mb-8">
                    {/* <p class="text-sm text-grey-dark flex items-center">
                    <svg class="text-grey w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                    </svg>
                    Members only
                  </p> */}
                    <div className="flex grid grid-cols-2">
                      <div>
                        <div class="text-black  text-xl mb-2">Your <span className='font-bold text-blue-400'>W</span> benefits</div>
                        <p class="text-grey-darker text-sm p-2">
                          <ul>
                            {
                              data.offers.map((off, index) => (
                                <React.Fragment key={index}>
                                  <li>✓ {off}</li>
                                </React.Fragment>
                              ))
                            }
                          </ul>
                        </p>
                      </div>
                      <div className='flex flex-col p- px-2'>
                        <div className='text-white text-center bg-green-400 rounded-full w-12 h-12 mx-auto'>
                          <p className='p-3 flex flex-col '>{data.rating !== undefined && data.rating}</p>
                        </div>
                        <div className='py-2 flex flex-col text-center'>
                          <p className='font-bold'>Excellent</p>
                          <p className='w-full '>{data.reviewNumber !== undefined && data.reviewNumber} reviews</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center ">
                    <div class="text-sm">
                      <p class="text-black text-center leading-none">Starting At</p>
                      <p class="text-grey-dark "><span className='font-bold text-center text-lg '>{data.startPrice}</span>/ Per Night</p>
                    </div>

                    <div className='items-end m-1 px-4'>
                      <button
                        type="submit"
                        onClick={(e) => handleOpen(e, data)}
                        className="  border-4 bg-blue-400 py-2 p-1  text-sm font-medium text-white  flex-end"
                      >
                        Book Directly 10% off
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}

      </div>
    </div >
  )
}

export default Homepage;