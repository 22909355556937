import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    ACTIVATE_ACCOUNT_FAIL,
    ACTIVATE_ACCOUNT_REQUEST,
    ACTIVATE_ACCOUNT_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
    SIGNUP_EMAIL_SUCCESS,
    SIGNUP_EMAIL_FAIL,
    SIGNUP_EMAIL_REQUEST,
    UPDATE_ACCOUNT_INFO_FAIL,
    UPDATE_ACCOUNT_INFO_REQUEST,
    UPDATE_ACCOUNT_INFO_SUCCESS,
    GET_PAYMENT_FAIL,
    GET_PAYMENT_REQUEST,
    GET_PAYMENT_SUCCESS,
    CANCEL_MEMBERSHIP_FAIL,
    CANCEL_MEMBERSHIP_REQUEST,
    CANCEL_MEMBERSHIP_SUCCESS
} from '../constants/UserConstant'

const initialState = {
    isAuth: Object.keys(JSON.parse(localStorage.getItem('user')) || []).length > 0 ? true : false,
    loading: false,
    user: Object.keys(JSON.parse(localStorage.getItem('user')) || []).length > 0 ? JSON.parse(localStorage.getItem('user')) : null,
    error: null,
    message: null,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                isAuth: false,
                user: null,
                message: null
            }

        case UPDATE_ACCOUNT_INFO_REQUEST:
            return {
                ...state,
                error: null,
                loading: true
            }
        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                user: action.payload.member,
                isAuth: true,
                loading: false
            }
        case UPDATE_ACCOUNT_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload.user,
                isAuth: true,
                message: action.payload.message
            }
        case CANCEL_MEMBERSHIP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case CANCEL_MEMBERSHIP_SUCCESS:
            return {
                ...state,
                error: null,
                user: action.payload.member,
                isAuth: true,
                loading: false,
                message: action.payload.message
            }

        case CANCEL_MEMBERSHIP_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case UPDATE_ACCOUNT_INFO_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case ACTIVATE_ACCOUNT_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                isAuth: false,
            }

        case ACTIVATE_ACCOUNT_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: false,
                message: null,
            }

        case ACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                user: action.payload.user,
                isAuth: true,
                message: action.payload.message
            }



        case REGISTER_USER_FAIL:
        case LOGIN_FAIL:
            return {
                ...state,
                error: action.payload,
                isAuth: false,
                user: null,
                loading: false
            }

        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isAuth: true,
                loading: false,
                error: null,
                user: action.payload.user
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                isAuth: true,
                loading: false,
                error: null,
                user: action.payload.user
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                message: null,
                error: null,
                loading: false,
                user: null,
                isAuth: false,
            };


        default:
            return state;
    }
}

const forgotState = {
    loading: false,
    message: null,
    error: null,
    success: false
}


export const forgotReducer = (state = forgotState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                message: action.payload,
            }

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
                message: action.payload.message
            };

        case FORGOT_PASSWORD_FAIL:
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false,
                message: forgotState.message
            }

        default:
            return state;
    }
}

const activateState = {
    error: null,
    loading: false,
    message: null,
    success: false
}

export const activateReducer = (state = activateState, action) => {
    switch (action.type) {
        case SIGNUP_EMAIL_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
                success: false
            }

        case SIGNUP_EMAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: false,
                message: null
            }

        case SIGNUP_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true,
                message: action.payload.message
            }

        default:
            return state
    }
}