import React, { useState, useEffect } from 'react';
import { forgotpassword } from '../redux/actions/UserAction';
import { useDispatch, useSelector } from 'react-redux';

const Forget = () => {
    const dispatch = useDispatch()
    const { message, loading } = useSelector((state) => state.forgotReducer);

    const [email, setEmail] = useState('');
    // error states
    const [displayError, setDisplayError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState("")

    const emailValidation = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || regex.test(email) === false) {
            return false;
        }
        return true;
    }

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (email.trim().length === 0) {
            displayErrorFunction("Please fill the required fields")
        } else if (!emailValidation()) {
            displayErrorFunction("Please enter a valid email")
        } else {
            dispatch(forgotpassword(email))
                .then(res => {
                    if (!res.success) {
                        displayErrorFunction(res.message)
                    } else {
                        setSuccess(res.message)
                    }
                })
        }
    }

    const displayErrorFunction = (message) => {
        setErrorMessage(message)
        setDisplayError(true)
        setTimeout(() => {
            setDisplayError(false)
            setErrorMessage("")
        }, 3000);
    }



    return (
        <div>
            <div className="flex items-center h-96">
                <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-sm m-auto">
                    {!displayError ? <></> : <span>{errorMessage}</span>}
                    <div className="mb-4">
                        <label
                            className="block font-bold mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            className="border border-gray-400 p-2 w-full"
                            id="email"
                            type="email"
                            value={email}
                            onChange={handleChange}
                        />
                    </div>
                    {success !== "" ? <span>{success}</span> : <></>}

                    <div class="flex space-x-4 justify-center">
                        <button class="px-6 h-12 uppercase font-semibold tracking-wider 
                            border-2 border-grey bg-green-300 bg-teal-400 text-black " type="submit" onClick={handleSubmit}>
                            Forget Password
                        </button>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Forget;
